import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import { routes } from "../../constants";
import { login } from "../../services/ApiService";
import Logo from "../../assets/images/logo/main_logo.png";
import Egg from "../../assets/images/logo/egg.jpeg";
import { getIsLoggedIn, DappUI } from "@elrondnetwork/dapp-core";
// import Sound from "../../assets/sounds/welcome.mp3";
import ThankYou from '../../assets/images/common-images/thank_you.png';
import Done from '../../assets/images/common-images/tumuloy.png'
import CheckBoxCheck from '../../assets/images/common-images/checkBoxCheck.png';
import {
  Form,
  FormControl
} from "react-bootstrap";
import {
  checkRxLogin
} from "../../services/ApiService";
import {
  isMobile,
  browserName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
} from "react-device-detect";
import "./style.css";
import "./styles.css";
import useStyles from './styles';
import InnerPageHeader from "../../Components/InnerPageHeader";
// import Maiar from "../../assets/images/logo/maiar.png";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import {
  uploadMedia
} from "../../services/ApiService";

const LoginPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { WalletConnectLoginButton } = DappUI;
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [level, setLevel] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [scIdNo, setScIdNo] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [tabArr, setTabArr] = useState([
    { tabId: 1, tabName: 'Agahan', isSelect: true },
    { tabId: 2, tabName: 'Tanghalian', isSelect: false },
    { tabId: 3, tabName: 'Hapunan', isSelect: false },
  ])
  const [timeId, setTimeId] = useState(1);
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
      [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
      onWindowResize = () => {              
        clearTimeout(window.resizeLag)
        window.resizeLag = setTimeout(() => {
          delete window.resizeLag                       
          setOrientation(isLandscape() ? 'landscape' : 'portrait')
        }, 200)
      }

  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
  ),[])

  //NSabado
  useEffect(() => {
    loginHandler('b1d81852-2e39-4760-8bd3-448fe94f6ebb', '1S11t7yiJCsTeebmZQBfka')
  }, [])

  const isLoggedIn = getIsLoggedIn();
  useEffect(() => {
    if (isLoggedIn) {
      console.log("loggg", isLoggedIn);
      // localStorage.removeItem("persist:dapp-core-store");
      localStorage.setItem("isNewUser", "0");
      const user =
        localStorage.getItem("token") &&
        JSON.parse(localStorage.getItem("token"));
      if (user) {
        // window.location.href = routes.home;
        navigate(routes.home);
      }
    }
  }, [isLoggedIn]);

  // const loginHandler = async (oldId, apiKey, wefftWalletAddress) => {
  const loginHandler = async (oldId, apiKey) => {
    try {
      setLoader(true);
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        setLoader(false);
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        // localStorage.setItem("wefftWalletAddress", wefftWalletAddress);
        // localStorage.setItem("maiarAddress", wefftWalletAddress);
        localStorage.setItem("footerIndex", 0);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        localStorage.setItem("isNewUser", "1");
        localStorage.setItem("autoJoin", "0");
        navigate(routes.home);
        // window.open(routes.home);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const onDonePress = async (e) => {
    if (code.trim() == "") {
      setErrorMsg('Invalid Code');
    }
    else if (password.trim() == "" || password.length != 6) {
      setErrorMsg('Invalid Password');
    }
    else {
      window.scrollTo(0, 0);
      try {
        const response = await checkRxLogin({code: code, birthdate: password});
        if (response) {
          if (response.data.length > 0) {
            loginHandler(response.data[0].oldId, response.data[0].apiKey)
            return;
          }
          else {
            setErrorMsg('Invalid Code or Password')
          }
        }
      } catch (e) {
        console.log(e);
        setErrorMsg('Invalid Code or Password')
      }
    }
  }

  const handleFile = async (file) => {
    const formData = new FormData();
    if (!file) {
      window.alert(`!File`);
      return;
    }
    formData.append("file", file);

    const response = await uploadMedia(formData);
    // console.log("response", JSON.stringify(response));
    try {
      // const upload = await uploadResetaImage({imageBig: response.data.data[0].imageUrlBigUrl})
      // if (upload) {
      //   // console.log(`upload.response=${JSON.stringify(upload)}`)
      //   await fetchData();
      // }
    } catch (e) {
      console.log(e);
    }
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    const mArray = fileUploaded.name.split(".");
    const fileExtension = mArray[mArray.length - 1];
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File type not supported.`);
    }
    else {
      handleFile(fileUploaded);
    }
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const onTumuloyPress = () => {
    setLevel(2);
  }

  const medOptions = [
    { value: 1, label: 'Losartan' },
    { value: 2, label: 'Atorvastatin' },
    { value: 3, label: 'Metformin' },
  ];
  const defaultMedOption = medOptions[0];


  const dosOptions = [
    { value: 1, label: '20mg' },
    { value: 2, label: '50mg' },
    { value: 3, label: '500mg' },
  ];
  const defaultDosOption = dosOptions[0];

  const _onSelect = () => {

  }

  const tabAction = (index) => {
    let arr = tabArr
    arr.map((item, i) =>
        arr[i].isSelect = false
    )
    arr[index].isSelect = true
    setTimeId(index + 1);
    setTabArr(arr);
  }

  return (<></>)

  // return (
  //   <>
  //   {level == 0 && 
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         minHeight: "90vh",
  //         flexDirection: "column",
  //       }}
  //     >
  //       {loader && <Loader />}
  //       <img
  //         src={Logo}
  //         style={{
  //           width: 180,
  //           height: 180,
  //           borderRadius: "50%",
  //           objectFit: "cover",
  //           marginTop: 10,
  //           marginBottom: 10,
  //         }}
  //       />
  //       <div style={{display: 'flex', flexDirection: 'column', marginTop: 10, height: 360, alignItems: 'center'}}>
  //         <div style={{width: 300, height: 360, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
  //           <div
  //             style={{display: 'flex', flexDirection: 'column', width: 300, height: 360, position: 'absolute', backgroundColor: '#B0E0E6', borderRadius: 6, justifyContent: 'flex-start', alignItems: 'center'}}
  //           >
  //             <p style={{textAlign: 'left', paddingLeft: 30, fontWeight: '500', marginTop: 30, width: 300, color: 'black', fontSize: 22}} >Code:</p>
  //             <Form inline>
  //               <FormControl
  //                 style={{ width: 240, fontSize: 20 }}
  //                 type="text"
  //                 maxLength={20}
  //                 value={code}
  //                 placeholder="Enter your code"
  //                 onChange={(e) => {
  //                   setCode(e.target.value)
  //                   setErrorMsg("")
  //                 }}
  //               />{" "}
  //             </Form>
  //             <p style={{textAlign: 'left', paddingLeft: 30, fontWeight: '500', marginTop: 30, width: 300, color: 'black', fontSize: 24}} >Password:</p>
  //             <Form inline>
  //               <FormControl
  //                 style={{ width: 240, fontSize: 20 }}
  //                 type="password"
  //                 minLength={6}
  //                 maxLength={6}
  //                 value={password}
  //                 placeholder="Enter your password"
  //                 onChange={(e) => {
  //                   setPassword(e.target.value)
  //                   setErrorMsg("")
  //                 }}
  //               />{" "}
  //             </Form>
  //             <div onClick={() => onDonePress()} style={{position: 'absolute', bottom: 30, cursor: 'pointer'}} >
  //               <img src={Done} style={{width: 150, height: 75, resizeMode: 'contain'}} />
  //             </div>
  //             <div onClick={() => setLevel(1)} style={{position: 'absolute', right: 10, bottom: 0, cursor: 'pointer'}} >
  //               <p style={{fontSize: 14, textDecoration: 'underline'}}>New User</p>
  //             </div>
  //             <p style={{textAlign: 'center', width: 300, color: 'red', fontSize: 14, position: 'absolute', bottom: 0}} >{errorMsg}</p>
  //           {/*<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: w * 0.8}}> 00599d
  //             <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8}}>
  //               { choice.map(item => {
  //                 return <div key={item.id} style={{cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: w * 0.8, alignItems: 'center'}} onClick={() => setSelectedId(item.id)} >
  //                   <p style={{color: '#000000', fontSize: 40}} >{item.name}</p>
  //                   <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 40, height: 40, borderWidth: 2, border: '3px solid #000000', borderRadius: 3, marginRight: 5, marginBottom: 10}}>
  //                     {item.id == selectedId && <img src={CheckBoxCheck} style={{width: 40, height: 40}}/>}
  //                   </div>
  //                 </div>
  //               }) }
  //             </div> */}
  //           </div>
  //         </div> 
  //       </div>
        
  //       <p style={{ color: "#db3eb1", marginTop: 20 }}>
  //         {process.env.REACT_APP_VERSION_PREFIX} {process.env.REACT_APP_VERSION}
  //       </p>
  //       {/* <video
  //         muted={false}
  //         playsInline
  //         preload="auto"
  //         src={Sound}
  //         loop
  //         autoPlay
  //         alt="icon"
  //         style={{ width: 0, height: 0 }}
  //       /> */}
  //       {!isMobile && (
  //         <p
  //           style={{
  //             color: "#404040",
  //             fontSize: 13,
  //             marginTop: 5,
  //             fontFamily: "CanaroBook",
  //           }}
  //         >
  //           *Best viewed on mobile browser
  //         </p>
  //       )}
  //       {isMobile && isIOS && !isSafari && (
  //         <p
  //           style={{
  //             color: "#404040",
  //             fontSize: 13,
  //             marginTop: 5,
  //             fontFamily: "CanaroBook",
  //           }}
  //         >
  //           *Best viewed on Safari
  //         </p>
  //       )}
  //       {isMobile && isAndroid && !isChrome && (
  //         <p
  //           style={{
  //             color: "#404040",
  //             fontSize: 13,
  //             marginTop: 5,
  //             fontFamily: "CanaroBook",
  //           }}
  //         >
  //           *Best viewed on Chrome
  //         </p>
  //       )}
  //       {(!isMobile || (isMobile && isIOS && !isSafari) || (isMobile && isAndroid && !isChrome)) && orientation == 'landscape' ? (
  //         <p
  //           style={{
  //             color: "#404040",
  //             fontSize: 13,
  //             marginTop: 0,
  //             fontFamily: "CanaroBook",
  //           }}
  //         >
  //           and in portrait mode
  //         </p>
  //       ) : orientation == 'landscape' && <p
  //         style={{
  //           color: "#404040",
  //           fontSize: 13,
  //           marginTop: 0,
  //           fontFamily: "CanaroBook",
  //         }}
  //       >
  //         *Best viewed in portrait mode
  //       </p>}
  //       {Number(process.env.REACT_APP_ON_BREAK) === 1 &&
  //         <div
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             minHeight: "100vh",
  //             flexDirection: "column",
  //             cursor: 'pointer'
  //           }}
  //           onClick={() => window.open('https://docs.google.com/spreadsheets/d/147w8LqW2uEFM9HZCHYx6HM2uvMHvXhTMlRI4ZgjN5JE/edit?fbclid=IwAR3DzZFU5Lqv-ZX8DEbArC8kY8A1RCchHjXSi8Yc6ipbCLcHu1rTx_WpY_M#gid=0', "_blank")}
  //         >
  //           <img src={ThankYou} style={{
  //             display: "flex",
  //             alignItems: "center",
  //             height: '100%',
  //             width: '100%',
  //             maxWidth: '480px',
  //             position: 'absolute',
  //             left: 'auto',
  //             top: 0
  //           }} />
  //         </div>
  //       }
  //     </div>
  //   }
  //   {level == 1 &&
  //     <div className={classes.loginContainer}>
  //       <div className={classes.loginContainer}>
  //         <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
  //           <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
  //             <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660980613667.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
  //           </div>
  //           <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
  //             <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>First Name:</p>
  //             <Form inline>
  //               <FormControl
  //                 style={{ width: 240, fontSize: 20, color: '#00599d' }}
  //                 type="text"
  //                 maxLength={30}
  //                 value={firstName}
  //                 placeholder=""
  //                 onChange={(e) => {
  //                   setFirstName(e.target.value)
  //                   setErrorMsg("")
  //                 }}
  //               />{" "}
  //             </Form>
  //           </div>
  //           <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
  //             <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>Last Name:</p>
  //             <Form inline>
  //               <FormControl
  //                 style={{ width: 240, fontSize: 20, color: '#00599d' }}
  //                 type="text"
  //                 maxLength={30}
  //                 value={lastName}
  //                 placeholder=""
  //                 onChange={(e) => {
  //                   setLastName(e.target.value)
  //                   setErrorMsg("")
  //                 }}
  //               />{" "}
  //             </Form>
  //           </div>
  //           <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
  //             <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>Birthday:</p>
  //             <Form inline>
  //               <FormControl
  //                 style={{ width: 240, fontSize: 20, color: '#00599d' }}
  //                 type="text"
  //                 maxLength={30}
  //                 value={birthday}
  //                 placeholder=""
  //                 onChange={(e) => {
  //                   setBirthday(e.target.value)
  //                   setErrorMsg("")
  //                 }}
  //               />{" "}
  //             </Form>
  //           </div>
  //           <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
  //             <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>Address:</p>
  //             <Form inline>
  //               <FormControl
  //                 style={{ width: 240, fontSize: 20, color: '#00599d' }}
  //                 type="text"
  //                 maxLength={30}
  //                 value={address}
  //                 placeholder=""
  //                 onChange={(e) => {
  //                   setAddress(e.target.value)
  //                   setErrorMsg("")
  //                 }}
  //               />{" "}
  //             </Form>
  //           </div>
  //           <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
  //             <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>Contact No.:</p>
  //             <Form inline>
  //               <FormControl
  //                 style={{ width: 240, fontSize: 20, color: '#00599d' }}
  //                 type="text"
  //                 maxLength={30}
  //                 value={contactNumber}
  //                 placeholder=""
  //                 onChange={(e) => {
  //                   setContactNumber(e.target.value)
  //                   setErrorMsg("")
  //                 }}
  //               />{" "}
  //             </Form>
  //           </div>
  //           <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
  //             <p style={{ marginLeft: 20, width: 120, fontSize: 20, marginTop: 10 }}>SC ID No.:</p>
  //             <Form inline>
  //               <FormControl
  //                 style={{ width: 240, fontSize: 20, color: '#00599d' }}
  //                 type="text"
  //                 maxLength={30}
  //                 value={scIdNo}
  //                 placeholder=""
  //                 onChange={(e) => {
  //                   setScIdNo(e.target.value)
  //                   setErrorMsg("")
  //                 }}
  //               />{" "}
  //             </Form>
  //           </div>
  //           <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
  //             <div style={{width: 300, height: 300, cursor: 'pointer', marginTop: 20}} onClick={handleClick}>
  //               <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660981863926.png' style={{width: 300, height: 300, resizeMode: 'contain'}} />
  //             </div>
  //             <div
  //               style={{
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 alignItems: "center",
  //               }}
  //             >
  //               <input
  //                 type="file"
  //                 ref={hiddenFileInput}
  //                 onChange={handleChange}
  //                 style={{display: 'none'}} // Make the file input element invisible 
  //               />
  //             </div>
  //             <div onClick={() => onTumuloyPress()} style={{bottom: 0, cursor: 'pointer'}} >
  //               <img src={Done} style={{width: 150, height: 75, resizeMode: 'contain'}} />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', minWidth: 400}}>
  //         <InnerPageHeader hasBackBtn goBackClick={() => setLevel(0)} title="" style={{position: 'absolute'}}/>
  //       </div>
  //     </div>
  //   }
  //   { level == 2 && 
  //     <div className={classes.loginContainer}>
  //       <div className={classes.loginContainer}>
  //         <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
  //           <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
  //             <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660982943166.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
  //           </div>
  //         </div>
  //       </div>
  //       <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
  //         <p style={{ marginLeft: 20, width: 100, fontSize: 20, marginTop: 10 }}>Medicine:</p>
  //         <div style={{width: 200}}>
  //           <Dropdown options={medOptions} onChange={_onSelect} value={defaultMedOption} placeholder="Select an option" className='dropdown'/>
  //         </div>
  //       </div>
  //       <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 40, marginTop: 5}}>
  //         <p style={{ marginLeft: 20, width: 100, fontSize: 20, marginTop: 10 }}>Dosage:</p>
  //         <div style={{width: 200}}>
  //           <Dropdown options={dosOptions} onChange={_onSelect} value={defaultDosOption} placeholder="Select an option" className='dropdown'/>
  //         </div>
  //       </div>
  //       <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'center', width: '33%'}}>
  //         { tabArr.map((item,index) => {
  //           return <div key={item} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '33%'}} onClick={() => tabAction(index)}>
  //             <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderWidth: 2, border: '3px solid #000000', borderRadius: 3, marginRight: 5}}>
  //               {((timeId == 1 && item.isSelect) || (timeId == 2 && item.isSelect) || (timeId == 3 && item.isSelect)) && <img src={CheckBoxCheck} style={{width: 20, height: 20}}/>}
  //             </div>
  //             <p style={{fontSize: 16}}>{item.tabName}</p>
  //           </div>
  //         }) }
  //       </div>
  //       <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', minWidth: 400}}>
  //         <InnerPageHeader hasBackBtn goBackClick={() => setLevel(1)} title="" style={{position: 'absolute'}}/>
  //       </div>
  //     </div> 
  //   }
  //   </>
  // );
};

export default LoginPage;
