import "./style.css";
import { maxGuesses, seed, urlParam } from "../Words/util";
import { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import SideFooter from "../../Components/SideFooter";
import Header from "../../Components/Header";
import packageJson from '../../../package.json';

import Leaders from '../../assets/images/footer-images/leaders.png';
// import Tips from '../../assets/images/footer-images/tips.png';
import Prizes from '../../assets/images/footer-images/prizes.png';
import ImgConfetti from '../../assets/images/common-images/confetti.png';

import EntryFeeCover from "./EntryFeeCover";
import { useNavigate } from "react-router-dom";
import { ids, routes } from "../../constants";
import { getFeatured, getUserProfile, updateClass, getNotificationCount, checkUserVersion, getJoinSummary, getNotificationsConfetti, getLessons, getHomeDashboard, checkSerial } from "../../services/ApiService";
import { effortHelper } from "../../helpers/effortHelper";
import { nftHelper } from "../../helpers/nftHelper";
import MoreFooter from "./MoreFooter";
import useStyles from "./styles";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import Sidebar from "../../Components/Sidebar";
import Welcome from '../../assets/images/common-images/welcome_gift.png';
import WeakInternet from '../../assets/images/common-images/weak_internet.png';
import MainBG from '../../assets/images/common-images/main_bg.png';
import InvalidSerial from '../../assets/images/common-images/invalid_serial.png';
import Confetti from '../../assets/images/gifs/confetti.gif';
import Padala from '../../assets/images/footer-images/footer_padala_selected.png';
import Magtipid from '../../assets/images/footer-images/footer_magtipid_selected.png';
import Gamot from '../../assets/images/footer-images/footer_medicines_selected.png';
import Deliver from '../../assets/images/footer-images/footer_deliver_selected.png';
import CallToBuy from '../../assets/images/footer-images/footer_bumili_selected.png';
import Reorder from '../../assets/images/footer-images/footer_products_selected.png';
import Generics from '../../assets/images/footer-images/footer_medicines_selected.png';
import Promos from '../../assets/images/footer-images/footer_promo_selected.png';
import SavingsTag from '../../assets/images/common-images/savingsTag.png';
import CheckButton from '../../assets/images/common-images/checkIcon.png';
import Arrow from '../../assets/images/common-images/arrowBlue.png';
import FreeMedPrompt from '../../assets/images/common-images/free_med_prompt.png';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { borderRadius } from "@mui/system";

const PHARMACISTS = [
  {
      id: 1,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882223678.png',
      top: '3vh',
      left: '10%'
  },
  {
      id: 2,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882241869.png',
      top: '66vh',
      left: '7%'
  },
  {
      id: 3,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882260742.png',
      top: '20vh',
      left: '70%'
  },
  {
      id: 4,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882277965.png',
      top: '76vh',
      left: '62%'
  },
  {
      id: 5,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882293940.png',
      top: '45vh',
      left: '5%'
  },
  {
      id: 6,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882223678.png',
      top: '65vh',
      left: '72%'
  },
  {
      id: 7,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882241869.png',
      top: '15vh',
      left: '40%'
  },
  {
      id: 8,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882260742.png',
      top: '60vh',
      left: '38%'
  },
  {
      id: 9,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882277965.png',
      top: '22vh',
      left: '22%'
  },
  {
      id: 10,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882293940.png',
      top: '8vh',
      left: '78%'
  },
  {
      id: 11,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882277965.png',
      top: '46vh',
      left: '75%'
  },
  {
      id: 12,
      image: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1672882293940.png',
      top: '72vh',
      left: '25%'
  }
]

function App() {
  const [showEntryCover, setShowEntryCover] = useState(false)
  const [footerIndex, setFooterIndex] = useState(0)
  const [sideFooterIndex, setSideFooterIndex] = useState(0)
  const [userData, setUserData] = useState({ avatarSmall: "", classId: 1, difficultyClassId: 4 });
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [words, setWords] = useState();
  const [topics, setTopics] = useState();
  const [numbers, setNumbers] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const [isNewUser, setIsNewUser] = useState(false);
  const [joinSummary, setJoinSummary] = useState([]);
  const [showMaxJoin, setShowMaxJoin] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showConfettiData, setShowConfettiData] = useState(false);
  const [notifConfettiData, setNotifConfettiData] = useState("");
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [lessonData, setLessonData] = useState();
  const [isSoundMuted, setIsSoundMuted] = useState(true);
  const [showWeakInternet, setShowWeakInternet] = useState(false);
  const [dashData, setDashData] = useState([]);
  const [seed, setSeed] = useState(1)
  const [onBuyClicked, setOnBuyClicked] = useState(false);
  const [onBuyCounter, setOnBuyCounter] = useState(0);
  const [level, setLevel] = useState(0);
  const [serial, setSerial] = useState("");
  const [isInvalidSerial, setIsInvalidSerial] = useState(false);

  const footerIdx = Number(localStorage.getItem("footerIndex"));
  const itemIdx = Number(localStorage.getItem("itemIndex"));

  const navigate = useNavigate();
  const classes = useStyles();

  // const engagementData={
  //   newId: 4242,
  //   title: 'Word',
  //   preloaderImageBig: 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1649235005787.png',
  //   joinFee: 100
  // }

  useEffect(() => {
    if (onBuyClicked) {
      setOnBuyCounter(1)
    }
  }, [onBuyClicked])

  useEffect(() => {
    if (onBuyCounter > 60) {
      setOnBuyClicked(false)
      setOnBuyCounter(0)
      onChannelClick(ids.channelJackpot);
    }
    else if (onBuyCounter > 0) {
      const timer = setTimeout(() => {
        setOnBuyCounter(onBuyCounter + 1)
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [onBuyCounter])

  const fetchUserData = async () => {
    try {
      setLoader(true);
      const response = await getUserProfile();
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setUserData(response.data.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const logUser = async() => {
    try {
      setLoader(true);
      const response = await checkUserVersion({ platform: isIOS ? 'ios' : isAndroid ? 'android' : !isMobile ? 'desktop' : 'others', version: parseInt(process.env.REACT_APP_VERSION.replaceAll('.','')) });
      if (response) {
        const version = response.data.filter(item => item.id == 13) // use ios version
        // console.log(`###version=${JSON.stringify(version)}; global.versionInt=${process.env.REACT_APP_VERSION?.replace('.','')}`);
        if (version.length > 0) {
          if (Number(process.env.REACT_APP_VERSION?.replaceAll('.','')) < parseInt(version[0].value)) {
              window.location.reload(false);
              // console.log('reload')
          }
        }
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(`login=${e}`)
    }
  }

  const fetchGetWin = async () => {
    try {
      setLoader(true);
      const response = await getJoinSummary();
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setJoinSummary(response.data.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const fetchLessonData = async () => {
    try {
      const response = await getLessons();
      if (response) {
        const data = response.data.data;
        const head = data.filter(item => item.sortOrder == 1);
        const tail = data.filter(item => item.sortOrder !== 1);
        setLessonData([
          ...head,
          ...tail
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchNotifCount = async () => {
    try {
      setLoader(true);
      const response = await getNotificationCount();
      if (response) {
        setNotifCount(response.data.count);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const fetchNotifConfetti = async () => {
    try {
      setLoader(true);
      const response = await getNotificationsConfetti();
      if (response && response.data) {
        let item = response.data;
        let format = item.text_format
        let values = JSON.parse(JSON.stringify(JSON.parse(item.text_values)));
        for (let key in values) {
          var value = values[key]
          format = format.replace(`%${key}%`,value.trim())
        }
        setNotifConfettiData(format);
        setShowConfetti(true);
        setTimeout(() => { 
          setShowConfetti(false) 
          setShowConfettiData(true);
          setTimeout(() => {
            setShowConfettiData(false);
          }, 3000)
        }, 2000);
        setLoader(false);
      }
      else {
        setNotifConfettiData("");
        setShowConfetti(false);
        setShowConfettiData(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserData();
    // fetchGetSked();
    // fetchNotifCount();
    // fetchNotifConfetti();
    // fetchGetWin();
    // fetchLessonData();
    logUser();
    // setFooterIndex(footerIdx);
    // footerChanged(footerIdx);
    // if (Number(localStorage.getItem("isNewUser")) === 1) {
    //   setIsNewUser(true);
    // }
    // fetchHomeDash();

    // setTimeout(function () { onChannelClick(ids.channelJackpot); }, 200);
    // window.open("rxtipid://");
  }, [])

  const fetchHomeDash = async () => {
    try {
      setLoader(true);
      const response = await getHomeDashboard();
      if (response) {
        setDashData(response.data)
        console.log(``)
      }
    }catch (e) {
      setLoader(false);
      setShowWeakInternet(true);
      console.log(e);
    }
  }

  const fetchGetSked = async () => {
    try {
      setLoader(true);
      const response = await getFeatured();

      if (response) {
        const words = response.data.filter((item) => {
          return item.funTypeFamily.id == ids.FunTypeFamilyRondleWords;
        });
        if (words.length > 0) {
          loadWords(words[0].items);
        }
        else {
          setShowWeakInternet(true);
        }
        const topics = response.data.filter((item) => {
          return item.funTypeFamily.id == ids.FunTypeFamilyRondleTopics;
        });
        if (topics.length > 0) {
          loadTopics(topics[0].items);
        }
        else {
          setShowWeakInternet(true);
        }
        const numbers = response.data.filter((item) => {
          return item.funTypeFamily.id == ids.FunTypeFamilyRondleNumbers;
        });
        if (numbers.length > 0) {
          loadNumbers(numbers[0].items);
        }
        else {
          setShowWeakInternet(true);
        }
        setLoader(false);
      }
      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      setShowWeakInternet(true);
      console.log(e);
    }
  };

  const loadWords = (data) => {
    let arr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      // for paid tourney use /////////////
      newItem.funTypeFamilyId = ids.FunTypeFamilyRondleWords;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      newItem.joinType = item.joinType;
      newItem.gameType = item.gameType;
      newItem.isSpecial = item.isSpecial;
      newItem.targetScore = item.targetScore;
      newItem.topPlayers = item.topPlayers;
      newItem.joinFee = item.joinFee;
      newItem.joinTicket = item.joinTicket;
      newItem.joinHour = item.joinHour;
      newItem.joinFeeType = item.joinFeeType;
      newItem.potMoney = item.potMoney;
      newItem.potMoneyAsOf = item.potMoneyAsOf;
      newItem.userCap = item.userCap;
      newItem.engagementType = item.engagementType;
      newItem.tutorialUrl = item.tutorialUrl;
      newItem.joinRequirement = item.joinRequirement;
      /////////////////////////////////////
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    //Arranging the cache url for tourney videos
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      let dict = {
        ...arr[i],
        gameTitle: arr[i].title,
        backgroundImage: arr[i].imageBig,
        play: false,
      };
      newArr = newArr.concat([dict]);
    }

    setWords(newArr);
    if (footerIdx == 1) {
        // console.log(`newArr=${JSON.stringify(newArr)}; footerIdx=${footerIdx}`)
        setData(newArr);
        setSelectedSlide(itemIdx);
     }
  };

  const loadTopics = (data) => {
    let arr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      // for paid tourney use /////////////
      newItem.funTypeFamilyId = ids.FunTypeFamilyRondleTopics;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      newItem.joinType = item.joinType;
      newItem.gameType = item.gameType;
      newItem.isSpecial = item.isSpecial;
      newItem.targetScore = item.targetScore;
      newItem.topPlayers = item.topPlayers;
      newItem.joinFee = item.joinFee;
      newItem.joinTicket = item.joinTicket;
      newItem.joinHour = item.joinHour;
      newItem.joinFeeType = item.joinFeeType;
      newItem.potMoney = item.potMoney;
      newItem.potMoneyAsOf = item.potMoneyAsOf;
      newItem.userCap = item.userCap;
      newItem.engagementType = item.engagementType;
      newItem.tutorialUrl = item.tutorialUrl;
      newItem.joinRequirement = item.joinRequirement;
      /////////////////////////////////////
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    //Arranging the cache url for tourney videos
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      let dict = {
        ...arr[i],
        gameTitle: arr[i].title,
        backgroundImage: arr[i].imageBig,
        play: false,
      };
      newArr = newArr.concat([dict]);
    }

    setTopics(newArr);
    if (footerIdx == 0) {
        // console.log(`newArr=${JSON.stringify(newArr)}; footerIdx=${footerIdx}`)
        setData(newArr);
        setSelectedSlide(itemIdx);
     }
  };

  const loadNumbers = (data) => {
    let arr = data.map((item) => {
      var newItem = { ...item.engagement };
      // const images = this.state.engagementImages.filter(i => i.engagementId == newItem.id)
      // newItem.preloaderImageBig = (images.length > 0 && typeof images[0].preloaderImageBig !== 'undefined') ? images[0].preloaderImageBig : item.preloaderImageBig;
      // newItem.postloaderImageBig = (images.length > 0 && typeof images[0].postloaderImageBig !== 'undefined') ? images[0].postloaderImageBig : item.postloaderImageBig;
      // for paid tourney use /////////////
      newItem.funTypeFamilyId = ids.FunTypeFamilyRondleNumbers;
      newItem.preloaderImageBig = item.preloaderImageBig;
      newItem.postloaderImageBig = item.postloaderImageBig;
      newItem.joinType = item.joinType;
      newItem.gameType = item.gameType;
      newItem.isSpecial = item.isSpecial;
      newItem.targetScore = item.targetScore;
      newItem.topPlayers = item.topPlayers;
      newItem.joinFee = item.joinFee;
      newItem.joinTicket = item.joinTicket;
      newItem.joinHour = item.joinHour;
      newItem.joinFeeType = item.joinFeeType;
      newItem.potMoney = item.potMoney;
      newItem.potMoneyAsOf = item.potMoneyAsOf;
      newItem.userCap = item.userCap;
      newItem.engagementType = item.engagementType;
      newItem.tutorialUrl = item.tutorialUrl;
      newItem.joinRequirement = item.joinRequirement;
      /////////////////////////////////////
      if (!isNaN(item.engagement.id)) {
        newItem.newId = item.engagement.id;
      }
      newItem.id = `${item.engagement.id}`;
      return newItem;
    });
    //Arranging the cache url for tourney videos
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      let dict = {
        ...arr[i],
        gameTitle: arr[i].title,
        backgroundImage: arr[i].imageBig,
        play: false,
      };
      newArr = newArr.concat([dict]);
    }

    setNumbers(newArr);
    if (footerIdx == 2) {
        // console.log(`newArr=${JSON.stringify(newArr)}; footerIdx=${footerIdx}`)
        setData(newArr);
        setSelectedSlide(itemIdx);
     }
  };

  const sideFooterChanged = (index: number) => {
    setSideFooterIndex(index);
    switch (index) {
      case 0: 
        // _onShowTips('BOOST','https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660548514765.jpg');
        boostHandler();
        break;
      case 1:
        // _onShowTips('BROADCAST', 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660548602023.jpg');
        broadcastHandler();
        break;
      // case 0:
      //   tumawagHandler();
      //   break;
      // case 1:
      //   magChatHandler();
      //   break;  
      default:
    }
  }

  const footerChanged = (index: number) => {
    setFooterIndex(index);
    switch (index) {
      case 0:
        // tumawagHandler();
        break;
      case 1:
        // magChatHandler();
        break;  
      case 2:
        cartHandler();
        break;  
      default:
    }
    
    // setShowEntryCover(false);
    // setData([]);
    // setFooterIndex(index);
    // localStorage.setItem("footerIndex", index);
    // // console.log(`footerChanged.footerindex=${localStorage.getItem("footerIndex")}`);
    // setSelectedSlide(0);
    // if (footerIdx != index) {
    //   localStorage.setItem("itemIndex", 0);
    // }

    // switch (index) {
    //   case 0:
    //     setData(topics);
    //     break;
    //   case 2:
    //     setData(numbers);
    //     break;
    //   default:
    //     setData(words);
    //     break;
    // }
    // // console.log(`index=${index}; data=${JSON.stringify(data)}`)

    // fetchUserData();
    // computeClass();
  }

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    navigate(routes.login);
  };

  const boostHandler = () => {
    setLoader(false);
    navigate(routes.boost, { state: {userData: userData} });
  };

  const broadcastHandler = () => {
    setLoader(false);
    navigate(routes.broadcast, { state: {userData: userData} });
  };

  const notificationHandler = () => {
    setLoader(false);
    navigate(routes.notification, { state: {userData: userData} });
  };

  const storeHandler = () => {
    setLoader(false);
    navigate(routes.store, { state: {userData: userData} });
  }

  const makatipidHandler = () => {
    setLoader(false);
    navigate(routes.makatipid, { state: {userData: userData, dashData: dashData} });
  }

  const resetaHandler = () => {
    setLoader(false);
    navigate(routes.reseta, { state: {userData: userData, dashData: dashData} });
  }

  const deliveryHandler = () => {
    setLoader(false);
    navigate(routes.delivery, { state: {userData: userData, dashData: dashData} });
  }

  const bumiliHandler = (isFree: Boolean) => {
    setLoader(false);
    navigate(routes.bumili, { state: {userData: userData, dashData: dashData, isFree} });
  }

  const tanggapHandler = () => {
    setLoader(false);
    navigate(routes.tanggap, { state: {userData: userData, dashData: dashData} });
  }

  const uminomHandler = () => {
    setLoader(false);
    navigate(routes.uminom, { state: {userData: userData, dashData: dashData} });
  }

  const sumagotHandler = () => {
    setLoader(false);
    navigate(routes.sumagot, { state: {userData: userData, dashData: dashData} });
  };

  const tumawagHandler = () => {
    setLoader(false);
    navigate(routes.tumawag, { state: {userData: userData, dashData: dashData, serial} });
  };

  const cartHandler = () => {
    setLoader(false);
    navigate(routes.cart, { state: {userData: userData, dashData: dashData} });
  };

  const historyHandler = () => {
    setLoader(false);
    navigate(routes.history, {state: { userData: userData} });
  };

  const collectionHandler = () => {
    setLoader(false);
    navigate(routes.collection, {state: { userData: userData} });
  };

  const lessonHandler = () => {
    setLoader(false);
    navigate(routes.lesson, {state: { userData: userData, lessonData: lessonData} });
  }

  const transcribeHandler = () => {
    setLoader(false);
    navigate(routes.tlTranscribe, {state: { userData: userData} });
  }

  const sidebarIndexClicked = (index) => {
    switch (index) {
      case 0:
        notificationHandler();
        break;
      case 3:
        collectionHandler();
        break;
      case 4:
        historyHandler();
        break;
      case 6:
        logoutHandler();
        break;
      case 7:
        lessonHandler();
        break;
      case 8:
        transcribeHandler();
        break;  
      case 10:
        _onShowTips('GAMES', ['https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660555485538.jpeg']);
        break;
      default:
    }
  };

  const _onShowTips = (title: string, images: string[]) => {
    navigate(routes.show, {
      state: {
        title: title,
        images: images,
      },
    })
  }

  const gameTypeToRoute = (gameType: number) => {
    switch (gameType) {
      case ids.gameTypeWordle:
        return routes.word;
      case ids.gameTypeFillInTheBlanks:
        return routes.topic;
      case ids.gameType2048:
        return routes.number;
      case ids.gameTypeMultipleChoice:
        return routes.topic2;
      case ids.gameTypePinoyHenyo:
        return routes.henyo;
      case ids.gameTypePinoyHenyo2:
        // return routes.henyo2;  
        return routes.henyo;
      case ids.gameTypePinoyHenyo3:
        return routes.henyo3;  
      case ids.gameTypeLotto:
        return routes.lotto;
      case ids.gameTypeSlotMachine:
        return routes.slot;
      case ids.gameTypeFunType:
        return routes.funtyperunner  
    }
  }

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  const _onShowLeaderboard = (engagementData) => {
    // if (engagementData.funTypeFamilyId === ids.FunTypeFamilyRondleTopics) {
    //   navigate(routes.famers, { state: { engagementData: engagementData } });
    // }
    // else {
      navigate(routes.leaders, { state: { engagementData: engagementData } });
    // }
  }

  const profileClicked = () => {
    setSidebarOpen(false);
    navigate(routes.profile);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Number(localStorage.getItem("autoJoin")) == 1) {
        onJoin(selectedSlide ? Number(itemIdx) : selectedSlide, true);
        localStorage.setItem("autoJoin", "0");
      }
    }, 1000);
    return () => clearTimeout(timer);
  })
  
  const onJoin = (index: number) => {
    var item;
    if (footerIdx == 0 && topics.length > index) {
      item = topics[index];
    } else if (footerIdx == 1 && words.length > index) {
      item = words[index];
    } else if (footerIdx == 2 && numbers.length > index) {
      item = numbers[index];
    }
    // window.alert(`item.title=${item.title}`)
    if (new Date(item.joinEndDate) > new Date()) {
      if (item.funTypeFamilyId === ids.FunTypeFamilyRondleTopics) {
        const js = joinSummary.filter((js) => js.engagementId === item.newId);
        if (js.length > 0) {
          if (js[0].wins >= ids.MaxWinsTopic) {
            setShowMaxJoin(true);
          } else {
            navigate(gameTypeToRoute(item.gameType), {
              state: {
                engagementData: data[index],
                userData: userData,
                joinSummary: joinSummary.filter((js) => js.engagementId === item.newId),
                lessonData: lessonData,
                gameType: item.gameType,
                // for funtyperunner
                data: data[index],
                hasTarget: false,
                isSoundMuted: isSoundMuted,
              }
            });
          }
        }
        else {
          navigate(gameTypeToRoute(item.gameType), {
            state: {
              engagementData: data[index],
              userData: userData,
              joinSummary: joinSummary.filter((js) => js.engagementId === item.newId),
              lessonData: lessonData,
              gameType: item.gameType,
              // for funtyperunner
              data: data[index],
              hasTarget: false,
              isSoundMuted: isSoundMuted,
            }
          });
        }
      }
      else if (item.funTypeFamilyId === ids.FunTypeFamilyRondleWords) {
        const data = joinSummary.filter((js) => js.engagementId === item.newId);
        if (data.length > 0) {
          if (data[0].wins >= ids.MaxWinsWord) {
            setShowMaxJoin(true);
          } else {
            setShowEntryCover(true)
          }
        }
        else {
          setShowEntryCover(true)
        }
      }
      else if (item.funTypeFamilyId === ids.FunTypeFamilyRondleNumbers) {
        const data = joinSummary.filter((js) => js.engagementId === item.newId);
        if (data.length > 0) {
          if (data[0].wins >= ids.MaxWinsNumber) {
            setShowMaxJoin(true);
          } else {
            setShowEntryCover(true)
          }
        }
        else {
          setShowEntryCover(true)
        }
      }
    } else {
      window.location.reload(false);
    }
  }

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2
  };

  const tabArr =[
    // { tabId: ids.channelTourney, tabName: 'Send', selectImg: Padala },
    { tabId: ids.channelJackpot, tabName: 'Buy now', selectImg: CallToBuy },
    { tabId: ids.channelTourney, tabName: 'ReOrder', selectImg: Reorder },
    { tabId: ids.channelBattle, tabName: 'Generics', selectImg: Generics },
    { tabId: ids.channelFamers, tabName: 'Promos', selectImg: Promos },
  ]

  const onHostClick = (sourceId: number) => {
    const item = lessonData.filter((i) => i.source.id === sourceId)
    if (item.length > 0) {
      navigate(routes.lessonDetails, {state: {lessonData: item[0], fromPostloader: true}})
    }
    else {
      navigate(routes.lesson)
    }
  }

  const onChannelClick = (channelId) => {
    switch (channelId) {
      case ids.channelJackpot:
        tumawagHandler();
        break;
      // case ids.channelFamers:
      //   // _onShowTips('DELIVERY',[
      //   //   'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660632816861.jpeg',
      //   //   'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660543102851.jpeg'
      //   // ]);
      //   deliveryHandler();
      //   break;  
      // case ids.channelJackpot:
      //   bumiliHandler(false);
      //   break; 
      //   // tanggapHandler();
      //   // break;  
      // case ids.channelBrands:
      //   uminomHandler();
      //   break;
      // case ids.channelBattle:
      //   sumagotHandler();
      //   break;
      default:  
    }
  }

  const getDiscount = (tabId) => {
    const w = 100;
    switch(tabId) {
      case ids.channelJackpot:
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <p style={{color: 'red', fontSize: isMobile ? 9 : 14}}>Save up to</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: isMobile ? 20 : 30}}>
              <p style={{color: 'red', fontSize: isMobile ? 40 : 54, fontWeight: '600', width: isMobile ? 16 : 22}}>1</p>
              <p style={{color: 'red', fontSize: isMobile ? 40 : 54, fontWeight: '600'}}>0</p>
              <p style={{color: 'red', fontSize: isMobile? 11 : 14, position: 'absolute', left: isMobile ? isAndroid ? 39 : 44 : 62}}>%</p>
          </div>
        </div>
      case ids.channelTourney:
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <p style={{color: 'red', fontSize: isMobile ? 9 : 14}}>Save up to</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: isMobile ? 20 : 30}}>
              <p style={{color: 'red', fontSize: isMobile ? 40 : 54, fontWeight: '600', width: isMobile ? 16 : 22}}>1</p>
              <p style={{color: 'red', fontSize: isMobile ? 40 : 54, fontWeight: '600'}}>5</p>
              <p style={{color: 'red', fontSize: isMobile? 11 : 14, position: 'absolute', left: isMobile ? isAndroid ? 39 : 44 : 62}}>%</p>
          </div>
        </div>
      case ids.channelBattle:  
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <p style={{color: 'red', fontSize: isMobile ? 9 : 14}}>Save up to</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: isMobile ? 20 : 30}}>
              <p style={{color: 'red', fontSize: isMobile ? 40 : 54, fontWeight: '600', width: isMobile ? isAndroid ? 20 : 22 : 30}}>5</p>
              <p style={{color: 'red', fontSize: isMobile ? 40 : 54, fontWeight: '600'}}>0</p>
              <p style={{color: 'red', fontSize: isMobile? 11 : 14, position: 'absolute', left: isMobile ? isAndroid ? 42 : 46 : 66}}>%</p>
          </div>
        </div>
      case ids.channelFamers:
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <p style={{color: 'red', fontSize: isMobile ? 9 : 14}}>Get</p>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: isMobile ? 20 : 30}}>
              <p style={{color: 'red', fontSize: isMobile ? 16 : 24, fontWeight: '600', height: isMobile ? 4 : 10}}>FREE</p>
              <p style={{color: 'red', fontSize: isMobile ? 16 : 24, fontWeight: '600', height: isMobile ? 24 : 30}}>ITEMS</p>
          </div>
        </div>
      default:
        return <></>
    }
  }

  const w = 350;

  const _checkSerial = () => {
    if (serial.trim() == "") {
      setIsInvalidSerial(true)
    }
    else {
      checkSerial({serial})
      .then(({data}) => {
        if (data.status == "Valid") {
          setLevel(1)
        }
        else {
          setIsInvalidSerial(true)
        }
      })
      .catch(e => {
        console.warn('check serial ERROR', e.message);
      });
    }
  }

  console.log(`isInvalidSerial=${isInvalidSerial}`)

  return (
    <div className={"App-container"} style={{display: 'flex', opacity: 1}}>
      {level === 0 &&
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <img src={MainBG} style={{width: '100%', height: !isMobile ? '90vh' : '100%', marginTop: 0}} />
          <div style={{position: 'absolute', top: isMobile ? 150 : 220, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <form onSubmit={(event) => event.preventDefault()}>
              <input type="text" style={{width: w * 0.8, fontSize: 20, textAlign: 'center', marginTop: 30, border:"4px solid #9be0dd", borderRadius: 6}} 
                value={serial} placeholder="XX-XX-XXXX-XX"
                onChange={(event) => setSerial(event.target.value)} maxLength={13}
              />
            </form>
            <div style={{borderRadius: 10, backgroundColor: '#9be0dd', width: 180, height: 60, 
              display: 'flex', justifyContent: 'center', marginTop: 20, cursor: 'pointer'}} 
              onClick={() => _checkSerial()}
            >
              <p style={{color: '#404040', fontSize: 36, fontWeight: 600, height: 60, marginTop: 5}}>Enter</p>
            </div>
          </div>
          <div className={`bg_modal_background ${isInvalidSerial && 'modal_bg_show'}`}></div>
          <div className={`bg_modal ${isInvalidSerial && 'modal_show'}`}
            onClick={() => {
              setIsInvalidSerial(false);
            }}
          >
            <img src={InvalidSerial} className={classes.tutorialImage} />
          </div>
          <p style={{width: '100%', fontWeight: '500', color: '#404040', fontSize: 12, marginTop: 5, height: 12, textAlign: 'center', position: 'absolute', bottom: -30}} >v{packageJson.version}</p>
        </div>
      }
      {level === 1 && 
        <>
          {/* <img src={MainBG} style={{width: '100%', height: !isMobile ? '90vh' : '100%', marginTop: 0}} /> */}
          <div style={{width: '100%', height: !isMobile ? '90vh' : '90vh', marginTop: 0}} />
          {
            // PHARMACISTS.map(item => {
            //   return <div
            //       style={{width: 60, height: 60, 
            //           borderRadius: 30, backgroundColor: '#ffcaca', position: 'absolute',
            //           top: item.top, left: item.left 
            //       }}
            //   >
            //       <img
            //           style={{width: 60, height: 60, borderRadius: 30}}
            //           src={item.image}
            //       />
            //   </div>
            // })
          }
          <div
            style={{width: '100%',
            position: 'absolute', display: 'flex', backgroundColor: 'transparent', 
            justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
          >
            <div style={{display: 'flex', flexDirection: 'column', position: 'relative', alignItems: 'center', marginBottom: isMobile ? 25 : 100}}>
              <p style={{fontSize: 40, color: '#9be0dd', fontWeight: 'bold'}}>Tipid Card</p>
            </div>
            <div
              style={{width: w * 0.75, height: w * 0.75, borderRadius: 40, 
                display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                backgroundColor: 'transparent'
              }}
            >
              {onBuyClicked &&
                  <div 
                      style={{width: w * (0.62 + (onBuyCounter % 20 > 10 ? 20 - onBuyCounter % 20 : onBuyCounter % 20) * 0.003), height: w * (0.62 + (onBuyCounter % 20 > 10 ? 20 - onBuyCounter % 20 : onBuyCounter % 20) * 0.005), borderRadius: w * (0.31 + (onBuyCounter % 20 > 10 ? 20 - onBuyCounter % 20 : onBuyCounter % 20) * 0.0015), 
                          backgroundColor: '#9be0dd', display: 'flex',
                          justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'absolute', marginTop: 5,
                          cursor: 'pointer'
                      }}
                  >
                  </div>
              }
              <div onClick={() => setOnBuyClicked(true)}
                  style={{width: w * 0.62, height: w * 0.62, borderRadius: w * 0.31, backgroundColor: '#dc2828', display: 'flex',
                      justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'absolute', marginTop: 5,
                      cursor: 'pointer', border: 'solid 5px #9be0dd', 
                  }}
              >
                  <p style={{fontSize: 24, color: 'white', height: 0, fontWeight: 'bold'}}>Tap to</p>
                  <p style={{fontSize: 80, color: 'white', height: 86, fontWeight: 'bold'}}>BUY</p>
                  <p style={{fontSize: 24, color: 'white', height: 30, fontWeight: 'bold'}}>medicines</p>
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', position: 'relative', alignItems: 'center', marginTop: isMobile ? 25 : 100}}>
              <p style={{fontSize: 32, color: '#9be0dd', fontWeight: 'bold', height: 24, marginTop: 10}}>Get 50%</p>
              <p style={{fontSize: 32, color: '#9be0dd', fontWeight: 'bold'}}>DISCOUNT</p>
            </div>
          </div>
          {/* <Header
            key={seed}
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={true}
            avatar={userData.avatarSmall}
            notificationCount={notifCount}
            onBuyClick={() => sidebarIndexClicked(7)}
            onNotifClick={() => notificationHandler()}
            onStoreClick={() => storeHandler()}
          />
          {sidebarOpen && (
              <Sidebar
                isSoundMuted={false}
                notificationCount={notifCount}
                avatar={userData.avatarSmall}
                avatarClicked={profileClicked}
                setSidebarOpen={setSidebarOpen}
                sidebarIndexClicked={sidebarIndexClicked}
              />
            )} */}
          {/* { footerIndex == 0 && */}
          {
            // <div style={{display: 'flex', width: '100%', height: '85vh', alignItems: "center", flexDirection: 'column', marginTop: isMobile ? '16vh' : '18vh', maxWidth: isMobile ? 400 : 600}} >
            //   {tabArr.map((rowData, index) => {
            //     let buttonColor = '#dc2828';
            //     return <div key={rowData.tabId} style={{width: isMobile ? 300 : 340, height: '15vh', alignItems: 'center'}}>
            //       <button
            //         onClick={() => onChannelClick(rowData.tabId)}
            //         style={{
            //           padding: "0.5rem 0.75rem",
            //           width: isMobile ? isAndroid ? 280 : 300 : 340,
            //           paddingLeft: isMobile ? 30 : 60,
            //           color: "white",
            //           display: 'flex',
            //           alignItems: "center",
            //           justifyContent: 'center',
            //           height: isMobile ? 70 : "8vh",
            //           borderRadius: 20,
            //           backgroundColor: buttonColor,
            //           fontSize: isAndroid ? 28 : '32px',
            //           fontWeight: '400',
            //           border: 'solid 1px #dc2828'
            //         }}
            //       >
            //         <img
            //           src={rowData.selectImg}
            //           style={{
            //             width: isMobile ? 66 : '7.3vh',
            //             height: isMobile ? 66 : '7.3vh',
            //             objectFit: "cover",
            //             // borderRadius: "50%",
            //             backgroundColor: 'white',
            //             borderTopLeftRadius: 20,
            //             borderBottomLeftRadius: 20,
            //             position: 'absolute',
            //             left: isAndroid ? '7.2%' : isIOS ? '10.2%' : '16.7%',
            //             // left: isAndroid ? '9.2%' : isIOS ? '12.2%' : '16.7%', //for local
            //           }}
            //         />
            //         {rowData.tabName}
            //         <div style={{position: 'absolute', right: '5%', marginBottom: '8%'}}>
            //           <img 
            //             style={{width: '12vh', height: '12vh'}}
            //             src={SavingsTag}
            //           />
            //         </div>
            //         <div style={{position: 'absolute', right: isAndroid ? '9.5%' : '10%', marginBottom: '8%'}}>
            //           {getDiscount(rowData.tabId)}
            //         </div>
            //       </button>
            //       {/* {typeof dashData !== 'undefined' && dashData.length > 0 && index !== (tabArr.length - 1) &&
            //         <div style={{display: "flex", width: isMobile ? 300 : 340, alignItems: 'center', flexDirection: 'column'}}>
            //           <img
            //             style={{width: '5vh', height: '5vh', position: 'absolute', paddingBottom: 5}}
            //             src={Arrow}
            //           />
            //         </div>
            //       } */}
            //     </div>
            //   })}
            // </div>  
          }
          {/* {showEntryCover &&
            <EntryFeeCover 
              onCancel={() => setShowEntryCover(false)}
              onTips={() => _onShowTips(data[selectedSlide].title, data[selectedSlide].tipsImageBig) }
              engagementData={data[selectedSlide]}
              onJoin={() => {
                navigate(gameTypeToRoute(data[selectedSlide].gameType), {
                  state: {
                    engagementData: data[selectedSlide],
                    userData: userData,
                    joinSummary: joinSummary.filter((js) => js.engagementId === data[selectedSlide].newId),
                    lessonData: lessonData,
                    gameType: data[selectedSlide].gameType,
                    // for funtyperunner
                    data: data[selectedSlide],
                    hasTarget: false,
                    isSoundMuted: isSoundMuted,
                  },
                });
              }}
            />
          } */}
            
          {/* {isNewUser && (
              <div
                className={classes.tutorialContainer} */}
              {/* <div className={`bg_modal_background ${isNewUser && 'modal_bg_show'}`}></div>
              <div className={`bg_modal ${isNewUser && 'modal_show'}`}
                onClick={() => {
                  setIsNewUser(false);
                  localStorage.setItem("isNewUser", "0");
                }}
              >
                <img src={Welcome} className={classes.tutorialImage} />
              </div> */}
          {/* )} */}
          {/* {showMaxJoin && ( 
              // <div
          //   className={classes.tutorialContainer} */}
              {/* <div className={`bg_modal_background ${showMaxJoin && 'modal_bg_show'}`}></div>
              <div className={`bg_modal ${showMaxJoin && 'modal_show'}`}
                onClick={() => setShowMaxJoin(false)}
              >
                <img src={footerIndex === 0 ? MaxJoinTopic : footerIndex === 1 ? MaxJoinWord : MaxJoinNumber } className={classes.tutorialImage} />
              </div> */}
          {/* )} */}
          {/* {showConfetti && (
            <div
              className={classes.tutorialContainer}
            >
              <img src={Confetti} className={classes.tutorialContainer} />
            </div>
          )}
          {showConfettiData && (
            <div
              className={classes.confettipContainer}
            >
              <img src={ImgConfetti} className={classes.confettiContainer} />
              <p className={classes.confettip}>{notifConfettiData}</p>
            </div>
          )} */}
          {/* <div className={`bg_modal_background ${showWeakInternet && 'modal_bg_show'}`}></div>
            <div className={`bg_modal ${showWeakInternet && 'modal_show'}`}
              onClick={() => {
                setShowWeakInternet(false);
                fetchGetSked();
                fetchGetWin();
                setSeed(seed+1);
              }}
            >
            <img src={WeakInternet} className={classes.tutorialImage} />
          </div> */}
          {/* <div className={`bg_modal_background ${Number(localStorage.getItem("isNewUser")) == 1 && 'modal_bg_show'}`}></div>
            <div className={`bg_modal ${Number(localStorage.getItem("isNewUser")) == 1 && 'modal_show'}`}
              onClick={() => {
                localStorage.setItem("isNewUser", "0");
                _onShowTips('',[
                  'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660893654198.png', //free_1
                  'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660893668593.png'
                ])
              }}
            >
            <img src={FreeMedPrompt} className={classes.tutorialImage} />
          </div> */}
          {/* <SideFooter setIndex={(index: number) => sideFooterChanged(index)} index={sideFooterIndex} /> */}
          {/* <Footer setIndex={(index: number) => footerChanged(index)} index={footerIndex} /> */}
          <p style={{width: '100%', fontWeight: '500', color: '#404040', fontSize: 12, marginTop: 5, height: 12, textAlign: 'center', position: 'absolute', bottom: -30}} >v{packageJson.version}</p>
          <div style={{background: '#9be0dd', width: 'calc(100% + 0px)', height: '50px', maxWidth: '480px', position: 'absolute', bottom: isMobile ? 0 : -90}}></div>
          <div style={{background: '#9be0dd', width: 'calc(100% + 0px)', height: '50px', maxWidth: '480px', position: 'absolute', top: 0}}></div>
        </>
      }
    </div>
  );
}

export default App;


// localStorage.setItem("isNewUser", "1");