import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Phonecall_1.png';
import PhoneCall from '../../../assets/images/common-images/phoneCall.png';
import Messenger from '../../../assets/images/common-images/messenger.png';
import Viber from '../../../assets/images/common-images/viber.png'
import { io } from "socket.io-client";
import Prescription from '../../../assets/images/common-images/prescriptions.png';
import Orderlist from '../../../assets/images/common-images/orderlist.png';
import PrescriptionGray from '../../../assets/images/common-images/prescriptionsgray.png';
import OrderlistGray from '../../../assets/images/common-images/orderlistgray.png';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import {formatNumberWithComma} from '../../../utils/utils';
import ID from '../../../assets/images/common-images/id.png';
import IDOFF from '../../../assets/images/common-images/idOff.png';
import RiderWith1 from '../../../assets/images/common-images/riderWith1.png';
import moment from 'moment';

import {
  getCart, registerRx, checkPhoneNumber, login, getCCAgents, getDrugstore, getDrugstoreRx, getDiscountTypesPerDrugstoreRx, getDeliveryTypes,
  getUserProfile, uploadBought, sendSocketCommand, startCall, endCall, uploadMedia
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { borderRadius } from '@mui/system';
import MyButton from '../../../Components/Common/MyButton';
import packageJson from '../../../../package.json';
import Logo from '../../../assets/images/logo/main_logo.png';

import { OTSession, OTPublisher, OTSubscriber, OTStreams } from 'opentok-react';
import Api from '../../../services/Api';

const OpenTok = require("opentok");

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

socket.on("connect", (data) => {
  // console.log(`socket.data=${JSON.stringify(data)}`)  
})

const Tumawag = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [level, setLevel] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [code, setCode] = useState("");
  const [userId, setUserId] = useState();
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [showPreloader, setShowPreloader] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [showText, setShowText] = useState(false);
  const [textToShow, setTextToShow] = useState("");
  const [subTextToShow, setSubTextToShow] = useState("");
  const [imageToShow, setImageToShow] = useState("");
  const [dsData, setDsData] = useState([]);
  const [storeItemId, setStoreItemId] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showNewCart, setShowNewCart] = useState(false);
  const [sku, setSku] = useState([]);
  const [origSku, setOrigSku] = useState([]);
  const [shownDiscount, setShownDiscount] = useState(false);
  const [selectedDs, setSelectedDs] = useState();
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountTypesData, setDiscountTypesData] = useState([]);
  const [showFinalCost, setShowFinalCost] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [discountTypes, setDiscountTypes] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState();
  const [deliveryId, setDeliveryId] = useState(0);
  const [userData, setUserData] = useState();
  const [alreadyBought, setAlreadyBought] = useState(false);
  const [showMayWe, setShowMayWe] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showSms, setShowSms] = useState(false);
  const hiddenFileInputReseta = React.useRef(null);
  const hiddenFileInputOrderlist = React.useRef(null);
  const hiddenFileInputSendId = React.useRef(null);
  const [enableCapture, setEnableCapture] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [showDownload2, setShowDownload2] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [fileReseta, setFileReseta] = useState();
  const [fileOrderlist, setFileOrderlist] = useState();
  const [fileSendId, setFileSendId] = useState();
  const [idOn, setIdOn] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [token, setToken] = useState("");
  const [finalCart, setFinalCart] = useState(false);

  const props = useLocation().state;

  //20221216
  const apiKey = '47701341';
  // const sessionId = '1_MX40NzYzNzk0MX5-MTY3MjgxODQyMDY2OX4yRUl3S1Fwdm1xUk5oYWU0elZWM0xLOVl-fn4';
  // const token = 'T1==cGFydG5lcl9pZD00NzYzNzk0MSZzaWc9NmNjY2ZhZTlmNDBhOTA3ZWQzY2M5NDkzMmYyNmRkMDUyYWI5MTc2YjpzZXNzaW9uX2lkPTJfTVg0ME56WXpOemswTVg1LU1UWTNNamN5TnpRd01ERXhNSDVxTmxGWWR6SmtOWEprVGpGWGEyVjBZMk5HU0ZkUVRuVi1mbjQmY3JlYXRlX3RpbWU9MTY3MjcyNzQzMiZub25jZT0wLjQ2MjAzNjU0Mjg0NDk1Mjkmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY3NTMxOTQzMSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==';

  const opentok = new OpenTok(process.env.REACT_APP_OPENTOK_APIKEY, process.env.REACT_APP_OPENTOK_SECRET);

  useEffect(async () => {
    if (typeof userId !== 'undefined' && typeof selectedData !== 'undefined' && typeof selectedData.id !== 'undefined') {
      console.log(`userId=${userId}; selectedData=${JSON.stringify(selectedData)}; sessionId=${sessionId}; token=${token}`)
      // var tokenOptions = {
      //   expireTime: new Date().getTime() / 1000 + 40 * 60, // in 40mins like Zoom
      // };
      
      // Generate a token.
      // const tok = opentok.generateToken(sessionId, tokenOptions);
      // setToken(tok);

      // window.alert(`userId=${userId}; selectedData=${JSON.stringify(selectedData)}; sessionId=${sessionId}; token=${tok}`)
      _startCall(selectedData.id)
      // _onSendSocket(selectedData.id, "memberCalling", 0);
      // _onSendSocket(selectedData.id, "newMemberCalling", `0,${tok}`);

      const response1 = await getDrugstore();
      if (response1) {
        // console.log(`response1=${JSON.stringify(response1)}`)
        setDsData(response1.data.data);
      }

      const response2 = await getDiscountTypesPerDrugstoreRx();
      if (response2) {
        // console.log(`response2=${JSON.stringify(response2)}`)
        setDiscountTypes(response2.data);
      }
      
      const response3 = await getDeliveryTypes();
      if (response3) {
        // console.log(`response3=${JSON.stringify(response3)}`)
        const sDeliveryType = response3.data.data.filter(item => item.isSelected)[0];
        setDeliveryTypes(response3.data.data);
        setDeliveryFee(sDeliveryType.price);
        setSelectedDeliveryType(sDeliveryType);
      }
    }
  }, [userId]);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const response = await getCCAgents();
      if (response.data.length > 0) {
        setSelectedData(response.data[0]);
        setDataLoaded(true);
        const tempCode = randomString();
        setCode(tempCode);
      }
      else {
        setDataLoaded(true);
      }
    }

    // call the function
    fetchData()
    // make sure to catch any error
    .catch(console.error);

    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      console.log('hello')
      _endCall();
    });
  }, [])

  useEffect(() => {
    console.log(`2. code=${code}`)
    if (code !== "") {
      register(code);
    }
    // checkPhone(`+639178031010`);
  }, [code])

  useEffect(() => {
    if (storeItemId > 0) {
      console.log(`storeItemId=${storeItemId}`)
      let storeItem = dsData.filter(item => item.id == 3)[0].details.find(i => i.brand.id == Number(storeItemId))
      console.log(`storeItem=${JSON.stringify(storeItem)}`)
      if (storeItem) {
        setTextToShow(`${storeItem.brand.name} ${storeItem.dosage.name} (${storeItem.generic.name})`);
        setSubTextToShow(`₱${formatNumberWithComma(storeItem.brand.price,2)}`);
        setImageToShow(storeItem.brand.image);
        setShowText(true); 
        setShowNewCart(false);
        setShowUserDetails(false);
        setShowDiscount(false);
        setShowFinalCost(false);
        setShowConfirm(false);
        setShowMayWe(false);
        setShowImage(false);
      }
    }
  }, [storeItemId])

  useEffect(() => {
    if (deliveryId > 0) {
      const selected = deliveryTypes.filter(item => item.id == deliveryId)[0]
      // console.log(`selected=${JSON.stringify(selected)}`)

      setDeliveryFee(selected.price);
      setSelectedDeliveryType(selected);
      setTextToShow(`DELIVERY FEE: (${selected.name})`);
      setSubTextToShow(`₱${formatNumberWithComma(selected.price,2)}`);
      setShowText(true);
      setShowUserDetails(false);
      setShowDiscount(false);
      setFinalCart(false);
    }
  }, [deliveryId])

  const _getCart = async (userId) => {
    let response = await getCart({userId});
    if (response) {
      console.log(`5. getCart.response=${JSON.stringify(response)}; userId=${userId}`)
      const origSKU = response.data.data;
      // console.log(`_getCart=${JSON.stringify(origSKU)}`)
      setSku([...origSKU]);
      setOrigSku(origSKU);
      setShowText(false);
      setShowMayWe(false);
      setShowNewCart(true);
      setShowDiscount(false);
      setShowConfirm(false);
      if (response.data.data.length == 0) {
        setShowMayWe(true);
      }
    }
  }

  useEffect(() => {
    // console.log(`sku`)
    if (sku.length > 0) {
      _onDrugstorePress(false);
    }
  }, [sku])

  useEffect(() => {
    console.log('there')
    if (userData) {
      console.log('here')
      setShowUserDetails(true);
      setShowText(false);
      setShowMayWe(false);
      setShowNewCart(false);
      setShowDiscount(false);
      setShowFinalCost(false);
      setShowConfirm(false);
      _onDrugstorePress(false);
    }
  }, [userData])

  const _getUserProfile = async (phoneNumber) => {
    const response = await checkPhoneNumber({phoneNumber: encodeURI(phoneNumber)});
    if (response) {
      if (response.data.data) {
        console.log(`userId=${response.data.data.id}`)
        if (typeof response.data.data.id !== 'undefined') {
          const response1 = await getUserProfile({id: response.data.data.id});
          if (response1) {
            console.log(`userData=${JSON.stringify(response1.data.data)}`)
            setUserData(response1.data.data);
            const response2 = await getDiscountTypesPerDrugstoreRx();
            if (response2) {
              // console.log(`response2=${JSON.stringify(response2)}`)
              setDiscountTypes(response2.data);
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (showDiscount) {
      console.log('here')
      setShownDiscount(true);
    }
  }, [showDiscount])

  useEffect(() => {
    console.log(`showConfirm=${showConfirm}; alreadyBought=${alreadyBought}`)
    if (showConfirm && !alreadyBought) {
      _buy();
    }
  }, [showConfirm])

  const _onSendSocket = (userId, command, data) => {
    console.log(`command=${command}, data=${data}`)
    const params = data ?  {
      userId: userId,
      command: command,
      data: data
    } : { 
      userId: userId,
      command: command 
    }
    
    sendSocketCommand(params)
    .then(({data}) => {
      
    })
    .catch(e => {
      console.warn('send socket ERROR', e.message);
    });
  }

  const _startCall = (agentId) => {
    startCall({agentId})
    .then(({data}) => {
      // console.log(`data=${JSON.stringify(data)}`)
      const sId = data.sessionId;
      const tok = data.token;
      _onSendSocket(selectedData.id, "newMemberCalling", `0,${sId},${tok},${props.serial}`);
      setSessionId(sId);
      setToken(tok);
    })
    .catch(e => {
        console.warn('start call ERROR', e.message);
    });
  }

  const _buy = async () => {
    const selected = selectedDs.details;
    console.log(`userData=${JSON.stringify(userData)}`)
    const param = {
      userId: userData.userId,
      total: totalPrice,
      eta: new Date(sku[0].eta).toISOString().slice(0,-5), 
      deliveryFee: Number(deliveryFee),
      taxes: 0, //Number((totalPrice - totalDiscount) * taxPercentage), 
      address: userData.address,
      totalDiscount: totalDiscount,
      drugstoreId: selectedDs.id,
      details: selected.map((item) => {
        return {
          genericsId: item.generic.id,
          brandedId: item.brand.id,
          dosageId: item.dosage.id,
          quantity: item.quantity,
          price: item.brand.price,
          discountPercentage: item.brand.discountPercentage,
        }
      }),
      discounts: discountTypesData.filter(item => item.amount > 0).map((item) => {
        return {
          discountTypeId: item.id,
          isOneTime: item.is_one_time,
          isPercentage: item.is_percentage,
          isSeniorPwd: item.is_senior_pwd,
          amount: item.amount,
          value: item.value,
          discountValue: item.discountValue,
          subTotal: item.subTotal,
          sortOrder: item.sort_order
        }
      })
    }

    uploadBought(param)
    .then((data) => {
      setShowFinalCost(false);
      setAlreadyBought(true);
    })
    .catch(e => {
        console.warn('upload bought ERROR', e.message);
    });
  }

  const loginHandler = async (oldId, apiKey) => {
    try {
      setLoader(true);
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        setLoader(false);
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        // localStorage.setItem("wefftWalletAddress", wefftWalletAddress);
        // localStorage.setItem("maiarAddress", wefftWalletAddress);
        localStorage.setItem("footerIndex", 0);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        localStorage.setItem("isNewUser", "1");
        localStorage.setItem("autoJoin", "0");

        const user = response.headers.authorization;
        if (user !== null) {
          console.log(`new header`)
          Api.setAuthHeader(response.headers.authorization);
        }
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  const checkPhone = async (phoneNumber) => {
    const response = await checkPhoneNumber({phoneNumber: encodeURI(phoneNumber)});
    if (response) {
      if (response.data.data) {
        console.log(`4. checkPhone.response=${JSON.stringify(response.data.data)}`)
        await loginHandler(response.data.data.oldId, response.data.data.apiKey)
        // _getCart(response.data.data.id);
        setUserId(response.data.data.id);
        socket.emit("online", { userId: response.data.data.id });
        // socket.on("newCart", (data) => {
        //   console.log(`new cart`)  
        // })
        socket.on("addMedicine", (data) => {
          console.log(`addMedicine; data=${data}`)
          setImageToShow("")
          setStoreItemId(Number(data))
        });

        socket.on("newCart", (data) => {
          console.log(`newCart; data=${data}`)
          _getCart(userId);
        })
        socket.on("setDelivery", (data) => {
          console.log(`setDelivery; data=${data}`)
          setImageToShow("");
          setDeliveryId(Number(data));
        })
        socket.on("updateUserDetails", (data) => {
          console.log(`updateUserDetails; data=${data}`)
          setTimeout(() => {
            _getUserProfile(data);
          }, 1000)
        })
        socket.on("showDiscount", (data) => {
          console.log(`showDiscount; data=${data}`)
          setShowDiscount(true);
          setShowUserDetails(false);
          setShowText(false);
          setShowNewCart(false);
          setShowFinalCost(false);
          setShowConfirm(false);
        })
        socket.on("finalCart", (data) => {
          console.log('finalCart')
          setImageToShow("");
          setFinalCart(true);
          setShowFinalCost(false);       
          setShowFinalCost(true);            
        })
        socket.on("confirmOrder", (data) => {
          console.log('confirmOrder');
          setShowConfirm(true);
        })
        socket.on("showDeliverySms", (data) => {
          console.log("showDeliverySms");
          setShowConfirm(false);
          setShowSms(true);
          setShowDownload(false);
          setShowMayWe(false);
          setShowText(false);
        })
        // socket.on("endCall", (data) => {
        //   console.log("endCall");
        //   _endCall();
        // })
        socket.on("enableCapture", (data) => {
          console.log("enableCapture");
          setEnableCapture(true)
        })
        socket.on("showDownloadApp", (data) => {
          console.log("showDownloadApp");
          setEnableCapture(false);
          setShowMayWe(false);
          setShowSms(false);
          setShowConfirm(false);
          setShowText(false);
          setShowDownload(true);
        })
        socket.on("showPromoCodeSteps", (data) => {
          console.log("showPromoCodeSteps");
          setShowDownload(false);
          setShowDownload2(true);
        })
        socket.on("sendIdOn", (data) => {
          console.log("sendIdOn");
          // handleClickSendId();
          setIdOn(true);
        })
        socket.on("endCall", (data) => {
          console.log("endCall");
          _endCall();
        })
        socket.on("disconnect", () => {
          console.log("disconnect");
          _endCall();
        })
      }
    }
  }

  // useEffect(() => {
  //   if (showDownload) {
  //     const timer = setTimeout(() => {
  //       console.log('This will run after 5 second!')
  //       setShowDownload2(true);
  //       setShowDownload(false);
  //     }, 5000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [showDownload]);

  useEffect(() => {
    if (showFinalCost) {
      _onDrugstorePress(true);
    }
  }, [showFinalCost])

  useEffect(() => {
    console.log('finalCartxxx')
  }, [finalCart])

  const register = async (tempCode) => {
    console.log(`3. register: tempCode=${tempCode}; selectedData=${JSON.stringify(selectedData)}`)
    const response = await registerRx({
      firstname: tempCode,
      lastname: tempCode,
      displayName: `TEMP ${tempCode} ${tempCode}`,
      phoneNumber: `+${tempCode}`,
      address: tempCode,
      birthDate: `1985-01-01`,
      countryCode: 'PH',
      ccAgentId: selectedData.id
    });
    console.log(`register.response=${JSON.stringify(response)}`)
    if (response) {
      // console.log(`there`)
      checkPhone(`+${tempCode}`);
    }    
  }

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  useEffect(() => {
    // console.log(`userId=${userId}`)
    const interval = setInterval(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1)
      }
      else {
        clearInterval(interval);
        setShowPreloader(false);
      }
    }, 30);
    return () => clearInterval(interval);
  }, [percentage]);

  const randomString = () => {
    const length = 4;
    const chars = "123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  const w = 300;
  const h = 470;

  const renderItem = (item, index) => {
    // console.log('here')
    const maxText = 25;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div className="list-group">
      {/* <SwipeToDelete key={index} onDelete={onDelete} item={item}> */}
        <div key={index}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: '100%', textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.95, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.62}}>
              {/* <p style={{color: '#dc2828', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5}} >{item.quantity} @ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.32, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </div>
      {/* </SwipeToDelete> */}
    </div>
  }

  const _onDrugstorePress = async (showFinalCost) => {
    const response = await getDrugstoreRx();
    if (response) {
      if (response.data.data.length > 0) {
        setDsData(response.data.data);
        let data = [];
        // console.log(`dsData=${JSON.stringify(response.data.data)}`)
        // console.log(`sku=${JSON.stringify(sku)}`)
        response.data.data.map(store => {
          let total = 0;
          let details = [];      
          sku.filter(item => item.quantity > 0).map(selected => {
            // const storeItem = store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id)
            // const storeItem = this.state.isGeneric ? store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id) : store.details.find(i => i.brand.id == selected.brand.id)
            const storeItem = store.details.find(i => i.brand.id == selected.brand.id)
            if (storeItem) {
              total += storeItem.brand.price * selected.quantity;
              details.push({
                ...storeItem,
                quantity: selected.quantity
              })
            }
          })
          data.push({
            ...store,
            total,
            details: [...details]
          })
        })
        ///// buy now
        const selected = data.filter(item => !item.hasBorder)
        console.log(`selected=${JSON.stringify(selected)}`)
        if (selected.length > 0) {
          _onDsRowPress(selected[0], showFinalCost);
        }   
      }
    } 
  }

  const _onDsRowPress = (rowData, showFinalCost) => {
    // console.log(`rowData=${JSON.stringify(rowData)}`)
    // console.log(`discountTypes=${JSON.stringify(discountTypes)}`)
    const totalPriceHere = rowData.details.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0);
    // console.log(`totalPriceHere=${totalPriceHere}`)
    let totalDiscountHere = 0;
    const tempDT2 = discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 1 && totalPriceHere >= item.volume)
      .sort((a, b) => a.volume < b.volume ? 1 : -1)
    let tempDT3 = [...tempDT2.slice(0,1), ...discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 0)].sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
    // console.log(`tempDT3=${JSON.stringify(tempDT3)}`)
    const tempDT = tempDT3.map(item => {
      if (rowData.discount == 0 && item.id == 1) { //no member discount
        //force amount to 0 for later filtering
        return {
          ...item,
          amount: 0
        }
      }
      else {
        if (item.id == ids.discountTypeTipidCard) { //tipid card discount
          // console.log(`selectedSkus=${JSON.stringify(selectedSkus)}`)
          const disc = sku.filter(item => item.brand.discountPercentage > 0).reduce((a,v) =>  a = a + v.quantity * v.brand.price * v.brand.discountPercentage, 0 );
          totalDiscountHere += disc;
          return {
            ...item,
            discountValue: disc,
            subTotal: totalPriceHere - disc,
            isUsed: true
          }
        }
        else if (item.is_percentage == 1 && totalPriceHere > totalDiscountHere + (totalPriceHere - totalDiscountHere) * item.value) { //percentage
          const seniorDiscount = (totalPriceHere - totalDiscountHere) * item.value;
          totalDiscountHere += seniorDiscount;
          return {
            ...item,
            discountValue: seniorDiscount,
            subTotal: totalPriceHere - totalDiscountHere,
            isUsed: true
          }
        }
        else if (item.is_percentage == 0 && totalPriceHere > totalDiscountHere + item.amount) {
          totalDiscountHere += item.amount;
          return {
            ...item,
            discountValue: item.amount,
            subTotal: totalPriceHere - totalDiscountHere,
            isUsed: true
          }
        }
        else {
          return {
            ...item,
            isUsed: false
          }
        }
      }
    }).filter(item => item.isUsed);
    
    // console.log(`tempDT=${JSON.stringify(tempDT)}; totalDiscount=${totalDiscountHere}`)
    console.log(`shownDiscount=${shownDiscount}; showFinalCost=${showFinalCost}`)
    if (shownDiscount) {
      setSelectedDs(rowData);
      setTotalPrice(totalPriceHere);
      setTotalDiscount(totalDiscountHere);
      setDiscountTypesData(tempDT);
      setShowFinalCost(true);
      setShowDiscount(false);
      setShowNewCart(false);
    }
    else {
      setSelectedDs(rowData);
      setTotalPrice(totalPriceHere);
      setTotalDiscount(totalDiscountHere);
      setDiscountTypesData(tempDT);
      setShowFinalCost(false);
    }
  }

  const _endCall = () => {
    // window.open("about:blank", "_self");
    // window.close();
    navigate(routes.login)
    endCall({})
    .then(({data}) => {
      _onSendSocket(selectedData.id, "memberEndCall", null);
    })
    .catch(e => {
      console.warn('end call ERROR', e.message);
    });
  }

  const handleFile = async (file, fileType) => {
    const formData = new FormData();
    if (!file) {
      window.alert(`!File`);
      return;
    }
    formData.append("file", file);

    const response = await uploadMedia(formData);
    // console.log("response", JSON.stringify(response));
    try {
      // const upload = await updateAvatar({avatarBig: response.data.data[0].imageUrlBigUrl, avatarSmall: response.data.data[0].imageUrlSmallUrl})
      // if (upload) {
      //   // console.log(`upload.response=${JSON.stringify(upload)}`)
      //   await fetchData();
      // }
      const imageUrlBigUrl = response.data.data[0].imageUrlBigUrl
      if (fileType == 1) {
        setFileReseta(imageUrlBigUrl)
        setShowImage(true)
        setShowMayWe(false)
      }
      else if (fileType == 2) {
        setFileOrderlist(imageUrlBigUrl)
        setShowImage(true)
        setShowMayWe(false)
      }
      else if (fileType == 3) {
        setShowImage(false)
        setShowMayWe(false)
        setFileSendId(imageUrlBigUrl)
      }
      _onSendSocket(selectedData.id, "sendFile", `${fileType},${imageUrlBigUrl}`);
    } catch (e) {
      console.log(e);
    }
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = (event, fileType) => {
    const fileUploaded = event.target.files[0];
    const mArray = fileUploaded.name.split(".");
    const fileExtension = mArray[mArray.length - 1];
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File type not supported.`);
    }
    else {
      handleFile(fileUploaded, fileType);
    }
  };

  const handleClickReseta = event => {
    hiddenFileInputReseta.current.click();
  };

  const handleClickOrderlist = event => {
    hiddenFileInputOrderlist.current.click();
  };

  const handleClickSendId = event => {
    hiddenFileInputSendId.current.click();
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      {(level == 0 && dataLoaded && selectedData.length == 0) ?
        <div className={classes.homeContainer} >
          <div style={{ width: '100%', alignItems: 'center', borderRadius: 20, justifyContent: 'center', minWidth: 300, maxWidth: 300, marginTop: 70, border: 'solid 2px #9be0dd' }}>
            <>
            <div style={{backgroundColor: 'transparent', display: "flex", width: '100%', minHeight: 450, 
              top: 120, alignItems: 'center', justifyContent: 'center'}}>
              <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 20}}>All agents are busy right now</p>
                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 20, fontStyle: 'italic'}}>Please try again later</p>
              </div>
            </div>  
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={_endCall} title="" style={{position: 'absolute'}}
            />
          </div>
        </div> :
        <div className={classes.homeContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 300, maxWidth: 300, marginTop: 70, border: showFinalCost && shownDiscount ? 'none' : 'solid 2px #9be0dd', minHeight: h,  overflowX: 'hidden', overflowY: 'auto' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', marginTop: 0, alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start' }}>
              { sessionId !== "" && token !== "" &&
                <OTSession apiKey={apiKey} sessionId={sessionId} token={token} audioTrack={true} publishAudio={true} publishVideo={true} videoTrack={true}>
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w, width: w, marginTop: -1, top: 0,
                    borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden'}}
                  >
                    <OTStreams>
                      <OTSubscriber  properties={{ insertMode: "append", width: w-2, height: w }}/>
                    </OTStreams>
                    {selectedData && <div style={{display: 'flex', backgroundColor: '#00000090', width: w-2, position: 'absolute', top: w+12, justifyContent: 'center', alignItems: 'center', height: 60}}>
                        <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 10}}>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 20, height: 15}}>{selectedData.name}</p>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'white', fontSize: 16, fontStyle: 'italic', marginBottom: 10}}>{selectedData.position}</p>
                        </div>
                      </div>
                    }
                  </div>
                  <div style={{display: 'flex', width: 100, height: 100, justifyContent: 'center', alignItems: 'center', position: 'relative', paddingTop: 0,  bottom: 300, marginLeft: 1, borderTopRightRadius: 0, overflow: 'hidden'}}>
                    <OTPublisher properties={{
                        publishAudio: isAudioEnabled,
                        publishVideo: isVideoEnabled,
                        width: 100, height: 100,
                      }}
                    />
                  </div>
                </OTSession>
              }
            </div>
            </>
            {/* <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minHeight: w * ((showText && imageToShow !== "" && imageToShow) ? 1.5 : 0.85), width: w, marginTop: -100,  */}
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minHeight: w * ((showText && imageToShow !== "" && imageToShow) ? 1 : 0.85), width: w, marginTop: -100, 
              borderTopLeftRadius: 0, borderTopRightRadius: 0, overflowX: 'hidden', overflowY: 'auto'}}
            >
              <div style={{position: 'absolute', marginTop: h * 0.20, display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: w * 0.75, overflowY: 'auto'}}>
                {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 18}}>{textToShow}</p>}
                {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, marginTop: h * 0.02}}>{subTextToShow}</p>}
                {/* {showText  && imageToShow !== "" && imageToShow && <img src={imageToShow} style={{width: w * 0.6, height: w * 0.6, marginTop: h * 0.05}}/>} */}
              </div>
              {showMayWe && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.33}}>May we take</p>}
              {showMayWe && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.26}}>your order please?</p>}
              {showImage && <div style={{position: 'absolute', bottom: h * 0.16, width: w * 0.55, maxHeight: w * 0.5, overflowX: 'hidden', overflowY: 'scroll'}} onClick={() => enableCapture ? handleClickReseta() : {}}>
                  {fileReseta && <img src={fileReseta} style={{width: w * 0.5, height: w * 0.5, }} />}
                  {fileOrderlist && <img src={fileOrderlist} style={{width: w * 0.5, height: w * 0.5}} />}    
                </div>                    
              }
              {/* {(showMayWe || showImage) && <div style={{position: 'absolute', bottom: h * 0.05, left: isAndroid ? 45 : 38, width: 40, height: 40, cursor: 'pointer'}} onClick={() => enableCapture ? handleClickReseta() : {}} >
                  <img src={enableCapture ? Prescription : PrescriptionGray} style={{width: 40, height: 40}} />
                </div>
              } */}
              {/* {((showMayWe || showImage) && enableCapture) && <div style={{position: 'absolute', bottom: h * 0.05, left: isAndroid ? 45 : 38, width: 40, height: 40, cursor: 'pointer'}} onClick={() => enableCapture ? handleClickReseta() : {}} > */}
              {showMayWe && enableCapture && <div style={{position: 'absolute', bottom: h * 0.05, width: 100, height: 100, cursor: 'pointer'}} onClick={() => enableCapture ? handleClickReseta() : {}} >
                  <img src={Prescription} style={{width: 100, height: 100}} />
                </div>
              }
              {(showMayWe || showImage) && <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="file"
                    ref={hiddenFileInputReseta}
                    onChange={(event) => handleChange(event, 1)}
                    style={{display: 'none'}} // Make the file input element invisible 
                  />
                </div>
              }
              {(showMayWe || showImage) && <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="file"
                    ref={hiddenFileInputOrderlist}
                    onChange={(event) => handleChange(event, 2)}
                    style={{display: 'none'}} // Make the file input element invisible 
                  />
                </div>
              }
              {showMayWe && enableCapture && <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 12, position: 'absolute', bottom: 0, width: 100, textAlign: 'center'}}>Prescription</p>}
              {/* {((showMayWe || showImage) && enableCapture) && <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 8, position: 'absolute', bottom: 0, left: isAndroid ? 45 : 33, width: 40, textAlign: 'center'}}>Prescription</p>} */}
              {/* {(showMayWe || showImage) && <div style={{position: 'absolute', bottom: h * 0.05, right: isAndroid ? 50 : 40, width: 40, height: 40, cursor: 'pointer'}} onClick={() => enableCapture ? handleClickOrderlist() : {}} >
                  <img src={enableCapture ? Orderlist : OrderlistGray} style={{width: 40, height: 40}} />
                </div>
              } */}
              {/* {((showMayWe || showImage) && enableCapture) && <div style={{position: 'absolute', bottom: h * 0.05, right: isAndroid ? 50 : 40, width: 40, height: 40, cursor: 'pointer'}} onClick={() => enableCapture ? handleClickOrderlist() : {}} >
                  <img src={Orderlist} style={{width: 40, height: 40}} />
                </div>
              }
              {((showMayWe || showImage) && enableCapture) && <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 8, position: 'absolute', bottom: 0, right: isAndroid ? 48 : 38, width: 50, textAlign: 'center'}}>Order List</p>} */}
              {/* {showDownload && !showText && <a style={{textAlign: 'center', fontWeight: '500', color: '#99cffd', textDecoration: 'underline', textDecorationColor: '#99cffd', fontSize: 22, cursor: 'pointer', position: 'absolute', bottom: h * 0.34}}>Download the RxClub App</a>} */}
              {showDownload && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 18, cursor: 'pointer', position: 'absolute', bottom: h * 0.32}}>Thank you for using</p>}
              {showDownload && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 18, cursor: 'pointer', position: 'absolute', bottom: h * 0.24}}>{ids.appName}.</p>}
              {showDownload && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 18, position: 'absolute', bottom: h * 0.16}}>Wait for the text or call of</p>}
              {showDownload && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 18, position: 'absolute', bottom: h * 0.08}}>the Rider regarding your delivery.</p>}
              {showDownload2 && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 21, position: 'absolute', bottom: h * 0.40, width: w * 0.8}}>STEPS:</p>}
              {showDownload2 && !showText && <div style={{display: 'flex', flexDirection: 'row', position: 'absolute', bottom: h * 0.32, width: w * 0.8, justifyContent: 'flex-start'}}>
                  <p style={{textAlign: 'left', fontWeight: '500', width: 25, color: '#404040', fontSize: 20}}>1.</p>
                  <a href={isIOS ? 'https://www.apple.com/ph/app-store/' : 'https://play.google.com/store/apps'} target="_blank" style={{textAlign: 'left', fontWeight: '500', color: '#99cffd', textDecoration: 'underline', textDecorationColor: '#99cffd', fontSize: 22, cursor: 'pointer'}}>Download app</a>
                </div>
              }
              {showDownload2 && !showText && <p style={{textAlign: 'left', fontWeight: '500', width: w * 0.8, color: '#404040', fontSize: 20, position: 'absolute', bottom: h * 0.25}}>2. Input phone number</p>}
              {showDownload2 && !showText && <p style={{textAlign: 'left', fontWeight: '500', width: w * 0.65, color: '#404040', fontSize: 20, position: 'absolute', bottom: h * 0.19}}>and OTP</p>}
              {showDownload2 && !showText && <p style={{textAlign: 'left', fontWeight: '500', width: w * 0.8, color: '#404040', fontSize: 20, position: 'absolute', bottom: h * 0.12}}>3. Get additional Savings</p>}
              {showDownload2 && !showText && <div style={{position: 'absolute', bottom: h * 0.01, display: 'flex', flexDirection: 'row', width: w * 0.8, alignItems: 'center', justifyContent: 'center'}}>
                  <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 18, fontStyle: 'italic'}}>Tap Delivery icon</p>
                  <img src={RiderWith1} style={{width: 36, height: 36, marginBottom: 12, marginLeft: 5}}/>
                </div>
              }
              {!showText && showNewCart && !finalCart && !showFinalCost && !showDiscount && !showUserDetails && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    {sku.length > 0 && <div
                        style={{width: w, minHeight: h * 0.8, backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                      >
                        <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                          <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                              <div style={{minHeight: 20}}>
                                { sku.map((item,index) => renderItem(item, index)) }
                              </div>
                              <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Total Price:</p>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma(sku.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
                              </div>
                          </div> 
                        </div>   
                      </div> 
                    }
                  </div>      
                </div>
              }
              {/* {!showText && showUserDetails && !showNewCart && !showFinalCost && !showDiscount && <div style={{marginTop: 10, marginLeft: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                      <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>First Name:</p>
                      <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.firstname}</p>
                    </div>
                    <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Last Name:</p>
                      <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.lastname}</p>
                    </div>
                    <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Phone #:</p>
                      <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.phoneNumber}</p>
                    </div>
                    {userData.address && userData.address !== "" && <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                        <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Address:</p>
                        <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.address}</p>
                      </div>
                    }
                    { userData.scPwdId &&
                      <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>{userData.scId !== null ? 'SC' : 'PWD'} ID#:</p>
                        <p style={{fontSize: 20, color: '#404040', width: '45%', marginLeft: 5, textAlign: 'left'}}>{userData.scPwdId}</p>
                        <div style={{width: '15%', marginBottom: 6, cursor: 'pointer'}} onClick={() => idOn ? handleClickSendId() : {}}>
                          <img src={idOn ? ID : IDOFF} style={{width: 48, height: 48}} />
                        </div>
                        {idOn && <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="file"
                              ref={hiddenFileInputSendId}
                              onChange={(event) => handleChange(event, 3)}
                              style={{display: 'none'}} // Make the file input element invisible 
                            />
                          </div>
                        }
                      </div>
                    }
                    { (userData.scPwdIdUrl || fileSendId) && 
                      <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={userData.scPwdIdUrl || fileSendId} style={{width: w * 0.5, height: w * 0.5, marginLeft: w * 0.1, marginBottom: 20}} />
                      </div>
                    }
                    {typeof userData.Referror !== 'undefined' && userData.Referror &&
                      <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Referror:</p>
                        <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.Referror.firstname} {userData.Referror.lastname}</p>
                      </div>
                    }
                  </div>
                </div>
              } */}
              {/* {!showText && showDiscount && !showNewCart && !showFinalCost && !showUserDetails && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.025}}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                        <p style={{left: 10, color: '#404040', fontSize: 18, width: '65%', textAlign: 'left', fontWeight: '600'}} >TOTAL PRICE:</p>
                        <p style={{fontWeight: '600', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                      </div>
                      <div style={{marginTop: 40}}>
                        {discountTypesData.map((item, index) => {
                          return <>
                          { item.name.toUpperCase().includes('SENIOR') &&
                            <div style={{height: 20}} />
                          }
                          <div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 35}}>
                            { item.id == ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()}:</p> }
                            { item.is_percentage == 1 && item.id != ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }                    
                            { item.is_percentage == 0 && item.id != ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()}:</p> }                    
                            <p style={{fontWeight: '500', fontSize: 18, width: '30%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.discountValue,2)}</p>
                          </div>
                          { item.name.toUpperCase().includes('REFERRAL') &&
                            <div style={{height: 20}} />
                          }
                          </>
                        })}
                        <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >TOTAL DISCOUNTS:</p>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 50}} >₱{formatNumberWithComma(totalDiscount,2)}</p>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              } */}
              {/* {!showSms && !showText && showFinalCost && !showNewCart && !showUserDetails && !showDiscount && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                      <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                          { selectedDs.details.map((item,index) => renderItem(item, index)) }
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                            <p style={{left: 10, color: '#404040', fontSize: 17, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL PRICE:</p>
                            <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                          </div>
                          {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                              <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL DISCOUNTS:</p>
                              <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, fontStyle: 'italic', color: '#dc2828', textAlign: 'right'}}>₱{formatNumberWithComma(-totalDiscount,2)}</p>
                            </div>
                          }
                          {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 10}}>
                              <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >NET AMOUNT:</p>
                              <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#55b88a', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice-totalDiscount,2)}</p>
                            </div>
                          }
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                            <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >DELIVERY FEE ({selectedDeliveryType.name}):</p>
                            <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(deliveryFee,2)}</p>
                          </div>
                          <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Pay only:</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma((totalPrice - totalDiscount) + Number(deliveryFee),2)}</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 20, marginTop: 30}} >{selectedDs.name}</p>
                          </div>
                      </div> 
                    </div>  
                  </div>
                </div>
              } */}
              {!showSms && !showText && showConfirm && alreadyBought && !showFinalCost && !showNewCart && !showUserDetails && !showDiscount && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05, marginBottom: 30}}>
                      <div style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 40, height: 30}}>You saved</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 48, height: 54}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18}}>in your order</p>
                      </div> 
                      <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 40}}>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18, height: 32}}>Your Total Savings in {ids.appName}:</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 28, height: 28}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                      </div> 
                      {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 60, width: w * 0.8, marginLeft: w * 0.1}}>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Name: </p>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{userData.firstname} {userData.lastname}</p>
                      </div> 
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Address:</p>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{userData.address}</p>
                      </div> */}
                      {/* {typeof userData.Referror !== 'undefined' && userData.Referror &&
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                          <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Referror:</p>
                          <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{userData.Referror.firstname} {userData.Referror.lastname}</p>
                        </div>
                      } */}
                    </div> 
                  </div>
                </div>
              }
              {showSms && <>
                  <div style={{width: w * 0.95, minHeight: height * 0.4, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 60}}>
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>Please wait for the</p>
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>SMS link</p>
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>to track your order.</p>
                    </div> 
                    <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 20}}>
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 24, marginBottom: 10}}>Thank you!</p>
                    </div> 
                  </div> 
                </>
              }
            </div>
          </div>
          {showText && imageToShow &&
            <div className={classes.preloaderContainer} style={{backgroundColor: 'white', minHeight: height, height: '100%'}}>
              <div style={{minHeight: height, width, alignItems: 'center', flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 18, marginTop: h * 0.2}}>{textToShow}</p>
                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, marginTop: h * 0.02}}>{subTextToShow}</p>
                <img src={imageToShow} style={{width: w, height: w, marginTop: h * 0.05}}/>
              </div>
            </div>
          }
          {finalCart && !shownDiscount &&
            <div className={classes.preloaderContainer} style={{backgroundColor: 'white', minHeight: height}}>
              <div style={{minHeight: height, width, alignItems: 'center', flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
                <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                  <div style={{display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 40}}>
                    {/* <img src={Logo} style={{width: 84, height: 84}} /> */}
                    <p style={{fontSize: 24, fontWeight: '700', color: '#404040'}}>{ids.appName}</p>
                    <div style={{height: 1, width: w, backgroundColor: '#404040', marginTop: 10, marginBottom: 10}}></div>
                  </div>
                  {sku.length > 0 && <div
                      style={{width: w, minHeight: h * 0.8, backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                    >
                      <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                        <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                            <div style={{minHeight: 20}}>
                              { sku.map((item,index) => renderItem(item, index)) }
                            </div>
                            <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Total Price:</p>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma(sku.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
                            </div>
                        </div> 
                      </div>   
                    </div> 
                  }
                </div> 
              </div>
            </div>
          }
          { showUserDetails &&
            <div className={classes.preloaderContainer} style={{backgroundColor: 'white', minHeight: height, height: '100%'}}>
              <div style={{height, width, alignItems: 'center', flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
                <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                  <div style={{display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                    {/* <img src={Logo} style={{width: 84, height: 84}} /> */}
                    <p style={{fontSize: 24, fontWeight: '700', color: '#404040'}}>{ids.appName}</p>
                    <div style={{height: 1, width: w, backgroundColor: '#404040', marginTop: 10, marginBottom: 10}}></div>
                  </div>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                      <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>First Name:</p>
                      <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.firstname}</p>
                    </div>
                    <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Last Name:</p>
                      <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.lastname}</p>
                    </div>
                    <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Phone #:</p>
                      <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.phoneNumber}</p>
                    </div>
                    {userData.address && userData.address !== "" && <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                        <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Address:</p>
                        <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.address}</p>
                      </div>
                    }
                    {idOn && 
                      <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontSize: 16, color: '#dc2828', textAlign: 'left'}}>Tap icon to upload ID: </p>
                        <div style={{width: '15%', marginBottom: 8, cursor: 'pointer', marginLeft: 5}} onClick={() => idOn ? handleClickSendId() : {}}>
                          <img src={idOn ? ID : IDOFF} style={{width: 48, height: 48}} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="file"
                            ref={hiddenFileInputSendId}
                            onChange={(event) => handleChange(event, 3)}
                            style={{display: 'none'}} // Make the file input element invisible 
                          />
                        </div>
                      </div>
                    }
                    { userData.scPwdId &&
                      <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>{userData.scId !== null ? 'SC' : 'PWD'} ID#:</p>
                        <p style={{fontSize: 20, color: '#404040', width: '45%', marginLeft: 5, textAlign: 'left'}}>{userData.scPwdId}</p>
                        <div style={{width: '15%', marginBottom: 6, opacity: 0}}>
                          <img src={idOn ? ID : IDOFF} style={{width: 48, height: 48}} />
                        </div>
                      </div>
                    }
                    { (userData.scPwdIdUrl || fileSendId) && 
                      <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={userData.scPwdIdUrl || fileSendId} style={{width: w * 0.5, height: w * 0.5, marginLeft: w * 0.1, marginBottom: 20, objectFit: 'contain'}} />
                      </div>
                    }
                    {typeof userData.Referror !== 'undefined' && userData.Referror &&
                      <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Referror:</p>
                        <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{userData.Referror.firstname} {userData.Referror.lastname}</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          { showDiscount &&
            <div className={classes.preloaderContainer} style={{backgroundColor: 'white', minHeight: height}}>
              <div style={{height, width, alignItems: 'center', flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
                <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                  <div style={{display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                    {/* <img src={Logo} style={{width: 84, height: 84}} /> */}
                    <p style={{fontSize: 24, fontWeight: '700', color: '#404040'}}>{ids.appName}</p>
                    <div style={{height: 1, width: w, backgroundColor: '#404040', marginTop: 10, marginBottom: 10}}></div>
                  </div>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.025}}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                        <p style={{left: 10, color: '#404040', fontSize: 18, width: '65%', textAlign: 'left', fontWeight: '600'}} >TOTAL PRICE:</p>
                        <p style={{fontWeight: '600', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                      </div>
                      <div style={{marginTop: 40}}>
                        {discountTypesData.map((item, index) => {
                          return <>
                          { item.name.toUpperCase().includes('SENIOR') &&
                            <div style={{height: 20}} />
                          }
                          <div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 35}}>
                            { item.id == ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }
                            { item.is_percentage == 1 && item.id != ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }                    
                            { item.is_percentage == 0 && item.id != ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()}:</p> }                    
                            <p style={{fontWeight: '500', fontSize: 18, width: '30%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.discountValue,2)}</p>
                          </div>
                          {/* { index < discountTypesData.length - 1 &&
                            <div key={`st${index}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                              <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >Sub-Total:</p>
                              <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.subTotal,2)}</p>
                            </div>
                          } */}
                          { item.name.toUpperCase().includes('REFERRAL') &&
                            <div style={{height: 20}} />
                          }
                          </>
                        })}
                        <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#0a9830', fontSize: 28, marginTop: 20, height: 20}} >TOTAL DISCOUNTS:</p>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#0a9830', fontSize: 50}} >₱{formatNumberWithComma(totalDiscount,2)}</p>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          }
          {showFinalCost && shownDiscount &&
            <div className={classes.preloaderContainer} style={{backgroundColor: 'white', minHeight: height}}>
              <div style={{height, width, alignItems: 'center', flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
                <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                  <div style={{display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                    {/* <img src={Logo} style={{width: 84, height: 84}} /> */}
                    <p style={{fontSize: 24, fontWeight: '700', color: '#404040'}}>{ids.appName}</p>
                    <p style={{fontSize: 16, fontWeight: '700', color: '#dc2424', height: 15}}>DELIVERY RECEIPT</p>
                    <div style={{display: 'flex', flexDirection: 'row', height: 15}}>
                      <p style={{fontSize: 16, fontWeight: '600', color: '#404040'}}>No.:</p>
                      <p style={{fontSize: 16, fontWeight: '600', color: '#dc2828', marginLeft: 5}}>0000000123</p>
                    </div>
                    <div style={{height: 1, width: w, backgroundColor: '#404040', marginTop: 10, marginBottom: 15}}></div>
                    <p style={{fontSize: 14, color: '#404040', width: w, textAlign: 'left', height: 20}}>Name: {userData.firstname} {userData.lastname}</p>
                    <p style={{fontSize: 14, color: '#404040', width: w, textAlign: 'left', height: 20}}>Address: {userData.address}</p>                  
                    <p style={{fontSize: 14, color: '#404040', width: w, textAlign: 'left', height: 20}}>Contact No.: {userData.phoneNumber}</p>                  
                    <p style={{fontSize: 14, color: '#404040', width: w, textAlign: 'left', height: 20}}>Delivered by: RxRider</p>                  
                    <div style={{height: 1, width: w, backgroundColor: '#404040', marginTop: 0, marginBottom: 10}}></div>
                  </div>
                  <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                      { selectedDs.details.map((item,index) => renderItem(item, index)) }
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                        <p style={{left: 10, color: '#404040', fontSize: 17, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL PRICE:</p>
                        <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                      </div>
                      {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                          <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >- TOTAL DISCOUNTS:</p>
                          <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#0a9830', textAlign: 'right'}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                        </div>
                      }
                      {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 10}}>
                          <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >NET AMOUNT:</p>
                          <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice-totalDiscount,2)}</p>
                        </div>
                      }
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                        <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >DELIVERY FEE ({selectedDeliveryType.name}):</p>
                        <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(deliveryFee,2)}</p>
                      </div>
                      <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Pay only:</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 50}} >₱{formatNumberWithComma((totalPrice - totalDiscount) + Number(deliveryFee),2)}</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 14, marginTop: 30}} >{moment(new Date()).format("MMM DD, YYYY H:mmA")}</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 14, marginTop: 0}} >Drugstore: {selectedDs.name}</p>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          }
          {showPreloader && <div className={classes.preloaderContainer} style={{backgroundColor: 'white'}}>
              <div style={{height, width, flex: 1, alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                <div style={{backgroundColor: '#ffcaca', borderRadius: 20, display: "flex", width: w, height: h, position: 'absolute', 
                    top: 40, alignItems: 'center', justifyContent: 'center'}}>
                  <div style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={"https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1668333327614.jpg"} style={{width: w * 0.9, height: w * 0.9 * 647 / 405}}/>
                  </div>
                </div> 
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 100, marginRight: 40}}>
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent'}}>
                    <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 20, marginTop: h + 60, height: 20}}>Connecting your</p>
                    <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 20}}>RX Pharmacist</p>
                  </div>
                  <div style={{width: 48, height: 48, position: 'absolute', bottom: 120, right: 30}}>
                    <CircularProgressbarWithChildren
                      value={percentage}
                      strokeWidth={16}
                      styles={buildStyles({
                        pathColor: "#dc2828",
                        trailColor: "transparent"
                      })}
                    >
                      {/*
                        Width here needs to be (100 - 2 * strokeWidth)% 
                        in order to fit exactly inside the outer progressbar.
                      */}
                      <div style={{ width: "75%", position: 'absolute' }}>
                        <CircularProgressbar
                          counterClockwise
                          value={percentage}
                          strokeWidth={14}
                          styles={buildStyles({
                            rotation: 3 / 4 + 1 / 8,
                            pathColor: "#55b88a",
                            trailColor: "transparent"
                          })}
                        />
                      </div>
                      <div style={{ width: "55%", position: 'absolute' }}>
                        <CircularProgressbar
                          value={percentage}
                          strokeWidth={12}
                          styles={buildStyles({
                            rotation: 1 / 2,
                            pathColor: "#ffcaca",
                            trailColor: "transparent"
                          })}
                        />
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
                  {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 100}}>
                  <div style={{width: 12, height: 12}}>
                    <CircularProgressbar
                      value={percentage * 5}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: "#dc2828",
                        trailColor: "#ffcaca"
                      })}
                    />
                  </div>
                  <div style={{width: 14, height: 14, marginLeft: 15}}>
                    <CircularProgressbar
                      value={(percentage -20) * 5}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: percentage >= 20 ? "#dc2828" : 'transparent',
                        trailColor: percentage >= 20 ? "#ffcaca" : 'transparent'
                      })}
                    />
                  </div>
                  <div style={{width: 16, height: 16, marginLeft: 15}}>
                    <CircularProgressbar
                      value={(percentage -40) * 5}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: percentage >= 40 ? "#dc2828" : 'transparent',
                        trailColor: percentage >= 40 ? "#ffcaca" : 'transparent'
                      })}
                    />
                  </div>
                  <div style={{width: 18, height: 18, marginLeft: 15}}>
                    <CircularProgressbar
                      value={(percentage -60) * 5}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: percentage >= 60 ? "#dc2828" : 'transparent',
                        trailColor: percentage >= 60 ? "#ffcaca" : 'transparent'
                      })}
                    />
                  </div>
                  <div style={{width: 20, height: 20, marginLeft: 15}}>
                    <CircularProgressbar
                      value={((percentage - 80) * 5)}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor: percentage >= 80 ? "#dc2828" : 'transparent',
                        trailColor: percentage >= 80 ? "#ffcaca" : 'transparent'
                      })}
                    />
                  </div> 
                </div>*/}
              </div>
            </div>
          }
          {!showFinalCost && !shownDiscount && <p style={{textAlign: 'left', fontWeight: '500', width: w, color: '#404040', fontSize: 12, marginTop: 5, height: 12, textAlign: 'center', position: 'absolute', bottom: -30}} >v{packageJson.version}</p>}
          {/* {showNewCart && <div style={{background: '#9be0dd', width: 'calc(100% + 0px)', height: '60px', maxWidth: '480px', position: 'absolute', bottom: -90}}></div>} */}
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={_endCall} title="" style={{position: 'absolute'}}
              // rightButtonLabel="INPUT"
              // onRightPress={() => navigate(routes.cartInput, { state: {userId} })}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default Tumawag;