import { Navigate, Outlet } from "react-router-dom";
import LoginPage from "./pages/Login/index";
import HomePage from "./pages/HomePage/index";
import { routes } from "../src/constants";
// import Word from './pages/Words';
import MaiarPage from "./Components/MaiarPage";
import Tips from './pages/Tips';
import History from "./pages/History";
import Collection from "./pages/Collection";
import CollectionDetails from "./pages/Collection/Details";
import Profile from "./pages/Profile";
import Notification from "./pages/Notification";
// import Famers from "./pages/Famers";
// import Topic from "./pages/Topics";
// import Number from './pages/Numbers';
// import Leaderboard from "./pages/Leaderboard";
// import MultipleChoice from "./pages/MultipleChoice";
// import Lesson from "./pages/Lesson";
// import LessonDetails from "./pages/Lesson/LessonDetails";
// import Steal from './pages/Steal';
// import PinoyHenyo from './pages/PinoyHenyo';
// import PinoyHenyo2 from './pages/PinoyHenyo2';
// import PinoyHenyo3 from './pages/PinoyHenyo3';
// import SlotMachine from './pages/SlotMachine';
// import Lotto from "./pages/Lotto";
// import FunTypeRunner from './pages/FunTypeRunner';
// import Winners from "./pages/History/Winners";
import Sumagot from './pages/M2/Sumagot';
import Tumawag from './pages/M2/Tumawag';
import MagChat from "./pages/M2/MagChat";
import Uminom from "./pages/M2/Uminom";
import Reseta from "./pages/M2/Reseta";
import Bumili from "./pages/M2/Bumili";
import Tanggap from "./pages/M2/Tanggap";
import Makatipid from './pages/M2/Makatipid';
import TLSumagot from "./pages/M2/TeamLeader/TLSumagot";
import TLUminom from "./pages/M2/TeamLeader/TLUminom";
import TLTanggap from "./pages/M2/TeamLeader/TLTanggap";
import TLDelivery from "./pages/M2/TeamLeader/TLDelivery";
import TLTranscribe from "./pages/M2/TeamLeader/TLTranscribe";
import Store from "./pages/Store";
import StoreDetails from "./pages/Store/Details";
import Chart from "./pages/M2/Chart";
import Boost from "./pages/Boost";
import Broadcast from "./pages/Broadcast";
import BoostDetails from "./pages/Boost/Details";
import Delivery from "./pages/M2/Delivery";
import DeliveryDetails from "./pages/M2/Delivery/Details";

import Cart from './pages/RxTipid/Cart';
import CartInput from "./pages/RxTipid/CartInput";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <Outlet /> : <Navigate to={routes.home} />,
    children: [
      { path: routes.login, element: <LoginPage /> },

      { path: routes.homepage, element: <Navigate to={routes.login} /> },
      // { path: routes.unlock, element: <UnlockRoute /> },
      { path: routes.maiar, element: <MaiarPage /> },
    ],
  },
  {
    path: routes.homepage, element: user ? <Outlet /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.home, element: <HomePage /> },
      // { path: routes.word, element: <Word /> }, 
      { path: routes.show, element: <Tips /> },
      { path: routes.history, element: <History /> },
      { path: routes.collection, element: <Collection /> },
      { path: routes.collectionDetails, element: <CollectionDetails /> },
      { path: routes.profile, element: <Profile /> },
      { path: routes.notification, element: <Notification /> },
      // { path: routes.famers, element: <Famers /> },
      // { path: routes.topic, element: <Topic /> },
      // { path: routes.topic2, element: <MultipleChoice /> },
      // { path: routes.number, element: <Number /> },
      // { path: routes.leaders, element: <Leaderboard /> },
      // { path: routes.lesson, element: <Lesson /> },
      // { path: routes.lessonDetails, element: <LessonDetails /> },
      // { path: routes.steal, element: <Steal /> },
      // { path: routes.henyo, element: <PinoyHenyo /> },
      // { path: routes.henyo2, element: <PinoyHenyo2 /> },
      // { path: routes.henyo3, element: <PinoyHenyo3 /> },
      // { path: routes.lotto, element: <Lotto /> },
      // { path: routes.slot, element: <SlotMachine /> },
      // { path: routes.funtyperunner, element: <FunTypeRunner /> },
      // { path: routes.winners, element: <Winners /> },
      { path: routes.sumagot, element: <Sumagot />  },
      { path: routes.tumawag, element: <Tumawag />  },
      { path: routes.magchat, element: <MagChat /> },
      { path: routes.uminom, element: <Uminom /> },
      { path: routes.reseta, element: <Reseta /> },
      { path: routes.bumili, element: <Bumili /> },
      { path: routes.tanggap, element: <Tanggap /> },
      { path: routes.chart, element: <Chart /> },
      { path: routes.makatipid, element: <Makatipid /> },
      { path: routes.delivery, element: <Delivery /> },
      { path: routes.deliveryDetails, element: <DeliveryDetails /> },
      
      { path: routes.tlSumagot, element: <TLSumagot /> },
      { path: routes.tlUminom, element: <TLUminom /> },
      { path: routes.tlTanggap, element: <TLTanggap /> },
      { path: routes.tlDelivery, element: <TLDelivery /> },
      { path: routes.tlTranscribe, element: <TLTranscribe /> },

      { path: routes.store, element: <Store /> },
      { path: routes.storeDetails, element: <StoreDetails /> },
      { path: routes.boost, element: <Boost /> },
      { path: routes.boostDetails, element: <BoostDetails /> },
      { path: routes.broadcast, element: <Broadcast /> },

      { path: routes.cart, element: <Cart /> },
      { path: routes.cartInput, element: <CartInput /> },

    ],
  },
];

const elrondRoutes = [
  {
    path: routes.home,
    title: 'Home',
    component: HomePage
  },
  {
    path: routes.login,
    title: 'Login',
    component: LoginPage,
    authenticatedRoute: true
  }
];

export default getRoutes;
