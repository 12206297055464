import * as React from "react";

const MyButton = (props) => {
  const styles = {
    buttonEnabled: {
      width: 120,
      height: 50,
      backgroundColor: '#dc2828',
      borderRadius: 25,
      cursor: 'pointer',
      display: 'flex', flexDirection: 'column', marginTop: 20, justifyContent: 'center', alignItems: 'center'
    },
    buttonDisabled: {
      width: 120,
      height: 50,
      backgroundColor: '#c0c0c0',
      borderRadius: 25,
      display: 'flex', flexDirection: 'column', marginTop: 20, justifyContent: 'center', alignItems: 'center'
    }
  }

  return (
    <div style={{display: "flex", width: 270, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}
      onClick={() => props.disabled ? {} : props.onPress()}
    >
      <div style={props.disabled ? styles.buttonDisabled : styles.buttonEnabled}>
        <p style={{fontSize: 14, fontWeight: '600', color: props.disabled ? '#909090' : 'white', marginTop: 15, textAlign: 'center'}}>{props.label.toUpperCase()}</p>
        {!props.disabled && props.counter > 0 && <div style={{position: 'absolute', marginLeft: 110, marginBottom: 20, height: 20, width: 20, borderRadius: 10, backgroundColor: '#ffcaca',
              display: 'flex', justifyContent: 'center', alignItems: 'center'
            }}>
            <p style={{fontSize: 14, fontWeight: '600', color: '#dc2828', marginTop: 12}}>{props.counter}</p>
          </div>
        }
      </div>      
    </div>
  );
};

export default MyButton;
